const highAll = [
    // 高级分析中的路由
    {
        path: "HighAll",
        name: "HighAll",
        component: () => import("@/views/highmenu/HighAll"),
        meta: {
            title: "高级分析",

        },
    },
    // 各种差异分析
    // {
    //     path: "ROCline",
    //     name: "ROCline",
    //     component: () => import("@/views/AdvancedAnalysis/DifferenceTest/ROCline"),
    //     meta: {

    //     }
    // },
    //分组和弦图
    {
        path: "GroupChordsDetail",
        name: "GroupChordsDetail",
        component: () => import("@/views/AdvancedAnalysis/correlationAnalysis/GroupChordsDetail"),
        meta: {

        }
    },
    {
        path: "curveAnalysis",
        name: "curveAnalysis",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/curveAnalysis"),
        meta: {

        }
    },
    // 表达量分布气泡图
    {
        path: "distributionBubble",
        name: "distributionBubble",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/distributionBubble"),
        meta: {

        }
    },

    // 生存曲线
    {
        path: "survivalCurve",
        name: "survivalCurve",
        component: () => import("@/views/AdvancedAnalysis/SurvivalAnalysis/survivalCurve"),
        meta: {

        }
    },
    // 配对检验柱状图
    {
        path: "pairedTestBar",
        name: "pairedTestBar",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/pairedTestBar"),
        meta: {

        }
    },

    //表格与格式
    // 缺失值处理
    {
        path: "missValues",
        name: "missValues",
        component: () => import("@/views/tableFormat/missValues"),
        meta: {

        }
    },
    {
        path: "DataIntegrationDetail",
        name: "DataIntegrationDetail",
        component: () => import("@/views/tableFormat/DataIntegrationDetail"),
        meta: {

        }
    },
    // 数据归一化/标准化geneConversion
    {
        path: "dataNormalization",
        name: "dataNormalization",
        component: () => import("@/views/tableFormat/dataNormalization"),
        meta: {

        }
    },

    // P值矫正
    {
        path: "PValueCorrection",
        name: "PValueCorrection",
        component: () => import("@/views/tableFormat/PValueCorrection"),
        meta: {

        }
    },
    // Count，TPM，FPKM格式转换
    {
        path: "FPKMformatConversion",
        name: "FPKMformatConversion",
        component: () => import("@/views/tableFormat/FPKMformatConversion"),
        meta: {

        }
    },
    //降维分析
    {
        path: "3Dpca",
        name: "3Dpca",
        component: () => import("@/views/AdvancedAnalysis/DataDimensionReduction/3Dpca"),
        meta: {

        }
    },

    //富集分析
    // 对称条形图
    {
        path: "DuiChenDetail",
        name: "DuiChenDetail",
        component: () => import("@/views/zhuzhuangtu/DuiChenDetail"),
        meta: {

        }
    },
    //GSEA
    {
        path: "GSEA",
        name: "GSEA",
        component: () => import("@/views/AdvancedAnalysis/enrichmentAnalysis/GSEA.vue"),
        meta: {

        }
    },
    //GSEA
    {
        path: "GSVA",
        name: "GSVA",
        component: () => import("@/views/AdvancedAnalysis/enrichmentAnalysis/GSVA.vue"),
        meta: {

        }
    },
    //富集气泡图
    {
        path: "enrichmentPoint",
        name: "enrichmentPoint",
        component: () =>
            import("@/views/AdvancedAnalysis/enrichmentAnalysis/enrichmentPoint.vue"),
        meta: {

        }
    },
    //富集气泡图
    {
        path: "ChordBarDetail",
        name: "ChordBarDetail",
        component: () =>
            import("@/views/AdvancedAnalysis/enrichmentAnalysis/ChordBarDetail.vue"),
        meta: {

        }
    },
    //富集饼图
    {
        path: "EnrichmentPieDetail",
        name: "EnrichmentPieDetail",
        component: () =>
            import("@/views/AdvancedAnalysis/enrichmentAnalysis/EnrichmentPieDetail.vue"),
        meta: {

        }
    },
    //富集棒棒糖图
    {
        path: "EnrichedLollipopsDetail",
        name: "EnrichedLollipopsDetail",
        component: () =>
            import("@/views/AdvancedAnalysis/enrichmentAnalysis/EnrichedLollipopsDetail.vue"),
        meta: {

        }
    },
    //富集气泡图
    {
        path: "MicrobialEnrichmentDetail",
        name: "MicrobialEnrichmentDetail",
        component: () =>
            import("@/views/AdvancedAnalysis/enrichmentAnalysis/MicrobialEnrichmentDetail.vue"),
        meta: {

        }
    },
    //微生物富集分析
    {
        path: "ChordEnrichmentDetail",
        name: "ChordEnrichmentDetail",
        component: () =>
            import("@/views/AdvancedAnalysis/enrichmentAnalysis/ChordEnrichmentDetail.vue"),
        meta: {

        }
    },
    //KEGG
    {
        path: "KEGGEnrichment",
        name: "KEGGEnrichment",
        component: () =>
            import("@/views/AdvancedAnalysis/enrichmentAnalysis/KEGGEnrichment.vue"),
        meta: {

        }
    },
    //GO
    {
        path: "GOEnrichment",
        name: "GOEnrichment",
        component: () =>
            import("@/views/AdvancedAnalysis/enrichmentAnalysis/GOEnrichment.vue"),
        meta: {

        }
    },
    //富集和弦图(无p值)
    {
        path: "ChordDiagramDetail",
        name: "ChordDiagramDetail",
        component: () =>
            import("@/views/AdvancedAnalysis/enrichmentAnalysis/ChordDiagramDetail.vue"),
        meta: {

        }
    },
    //相关性气泡图
    {
        path: "CorrelationBubble",
        name: "CorrelationBubble",
        component: () =>
            import("@/views/AdvancedAnalysis/enrichmentAnalysis/CorrelationBubble.vue"),
        meta: {

        }
    },
    //差异气泡图
    {
        path: "differentialBubble",
        name: "differentialBubble",
        component: () =>
            import("@/views/AdvancedAnalysis/enrichmentAnalysis/differentialBubble.vue"),
        meta: {

        }
    },
    //富集网络图
    {
        path: "enrichmentNetwork",
        name: "enrichmentNetwork",
        component: () => import("@/views/AdvancedAnalysis/enrichmentAnalysis/enrichmentNetwork.vue"),
        meta: {

        }
    },
    //层级网络图
    {
        path: 'hierarchyNetwork',
        name: 'hierarchyNetwork',
        component: () => import('@/views/AdvancedAnalysis/enrichmentAnalysis/hierarchyNetwork.vue'),
        meta: {

        }
    },
    //DO富集
    {
        path: 'DOEnrichment',
        name: 'DOEnrichment',
        component: () => import('@/views/AdvancedAnalysis/enrichmentAnalysis/Reactome/DOEnrichment'),
        meta: {

        }
    },
    // Reactome富集
    {
        path: "Reactome",
        name: "Reactome",
        component: () => import("@/views/AdvancedAnalysis/enrichmentAnalysis/Reactome/Reactome"),
        meta: {
            title: "Reactome",

        },
    },

    //关联分析
    {
        // 桑基气泡图
        path: "SankeyBubbleDiagram",
        name: "SankeyBubbleDiagram",
        component: () =>
            import("@/views/AdvancedAnalysis/enrichmentAnalysis/SankeyBubbleDiagram"),
        meta: {
            title: "桑基气泡图",

        },
    },
    //相关性网络图
    {
        path: "correlationNetwork",
        name: "correlationNetwork",
        component: () => import("@/views/AdvancedAnalysis/correlationAnalysis/correlationNetwork"),
        meta: {

        }
    },
    //相关性和弦图（关联和弦图）
    {
        path: "relevanceChordDetail",
        name: "relevanceChordDetail",
        component: () => import("@/views/AdvancedAnalysis/correlationAnalysis/relevanceChordDetail"),
        meta: {

        }
    },
    //多尺度嵌入式基因共表达网络分析（MEGENA）
    {
        path: "GeneNetworkAnalysisDetail",
        name: "GeneNetworkAnalysisDetail",
        component: () => import("@/views/AdvancedAnalysis/correlationAnalysis/GeneNetworkAnalysisDetail"),
        meta: {

        }
    },
    //关联和弦图
    {
        path: "relatedChordDetail",
        name: "relatedChordDetail",
        component: () => import("@/views/AdvancedAnalysis/correlationAnalysis/relatedChordDetail"),
        meta: {

        }
    },
    //多变量关系图
    {
        path: "MultivariateRelationship",
        name: "MultivariateRelationship",
        component: () => import("@/views/AdvancedAnalysis/correlationAnalysis/MultivariateRelationship"),
        meta: {

        }
    },
    //相关性差异网络图
    {
        path: "correlationDifferential",
        name: "correlationDifferential",
        component: () => import("@/views/AdvancedAnalysis/correlationAnalysis/correlationDifferential"),
        meta: {

        }
    },
    {
        path: "RDA",
        name: "RDA",
        component: () => import("@/views/AdvancedAnalysis/correlationAnalysis/RDA"),
        meta: {

        }
    },
    {
        path: "generalAnalysis",
        name: "generalAnalysis",
        component: () => import("@/views/AdvancedAnalysis/correlationAnalysis/generalAnalysis"),
        meta: {

        }
    },
    //曼哈顿图
    {
        path: "manhattanplot",
        name: "manhattanplot",
        component: () => import("@/views/AdvancedAnalysis/correlationAnalysis/manhattanplot.vue"),
        meta: {

        }
    },


    // 聚类
    {
        path: "Cluster",
        name: "Cluster",
        component: () => import("@/views/AdvancedAnalysis/cluster/Cluster"),
        meta: {

        }
    },
    {
        path: "consistentClustering",
        name: "consistentClustering",
        component: () => import("@/views/AdvancedAnalysis/cluster/consistentClustering"),
        meta: {
            title: '一致性聚类',

        }
    },
    // 样本分布
    //{
    //  path: "SampleDistribution",
    //  name: "SampleDistribution",
    //  component: () =>
    //    import("@/views/AdvancedAnalysis/sampleDistribution/SampleDistribution"),
    //  meta: {
    //    title: "样本分布",
    //  },
    //},
    {
        // 双变量图
        path: "BivariateGraph",
        name: "BivariateGraph",
        component: () => import("@/views/AdvancedAnalysis/sampleDistribution/BivariateGraph"),
        meta: {
        }
    },
    // 样本分布甜甜圈
    {
        path: "DonutsSampleDistribution",
        name: "DonutsSampleDistribution",
        component: () => import("@/views/AdvancedAnalysis/sampleDistribution/DonutsSampleDistribution"),
        meta: {

        }
    },
    // 样本分布热图
    {
        path: "SampleDistributionheatmap",
        name: "SampleDistributionheatmap",
        component: () =>
            import("@/views/AdvancedAnalysis/sampleDistribution/SampleDistributionheatmap"),
        meta: {

        }
    },
    // 样本分布柱状图
    {
        path: "SampleDistributionHistogram",
        name: "SampleDistributionHistogram",
        component: () =>
            import("@/views/AdvancedAnalysis/sampleDistribution/SampleDistributionHistogram"),
        meta: {

        }
    },
    {
        // 热图柱状图
        path: "HeatMapBar",
        name: "HeatMapBar",
        component: () => import("@/views/AdvancedAnalysis/heatMap/HeatMapBar"),
        meta: {

        }
    },
    {
        // 点棒热图
        path: "DotBarHeatMap",
        name: "DotBarHeatMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/DotBarHeatMap"),
        meta: {

        }
    },
    {
        // 二值热图
        path: "BinaryHeatMap",
        name: "BinaryHeatMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/BinaryHeatMap"),
        meta: {

        }
    },
    {
        // 堆叠热图
        path: "StackHeatMap",
        name: "StackHeatMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/StackHeatMap"),
        meta: {

        }
    },
    // {
    //     // 基因突变热图
    //     path: "GeneMutationHeatMap",
    //     name: "GeneMutationHeatMap",
    //     component: () => import("@/views/AdvancedAnalysis/heatMap/GeneMutationHeatMap"),
    //     meta: {

    //     }
    // },
    {
        // 鱼头相关性热图
        path: "fishHeadCorrelationHeatma",
        name: "fishHeadCorrelationHeatma",
        component: () => import("@/views/AdvancedAnalysis/heatMap/fishHeadCorrelationHeatma"),
        meta: {

        }
    },
    {
        // 带对角线相关性热图
        path: "HeatMapWithDiagonalCorrelation",
        name: "HeatMapWithDiagonalCorrelation",
        component: () => import("@/views/AdvancedAnalysis/heatMap/HeatMapWithDiagonalCorrelation"),
        meta: {

        }
    },
    {
        // 组间相关性热图
        path: "IntergroupCorrelation",
        name: "IntergroupCorrelation",
        component: () => import("@/views/AdvancedAnalysis/heatMap/IntergroupCorrelation"),
        meta: {

        }
    },
    {
        // 双层环形热图
        path: "DoubleDeckToroidalHeatMap",
        name: "DoubleDeckToroidalHeatMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/DoubleDeckToroidalHeatMap"),
        meta: {

        }
    },
    {
        // 扇形热图
        path: "FanHeatMap",
        name: "FanHeatMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/FanHeatMap"),
        meta: {

        }
    },
    {
        // 分组相关性热图
        path: "GroupCorrelationHeatmap",
        name: "GroupCorrelationHeatmap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/GroupCorrelationHeatmap"),
        meta: {

        }
    },
    {
        // 聚类热图
        path: "BasicsHeatMap",
        name: "BasicsHeatMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/BasicsHeatMap"),
        meta: {

        }
    },
    {
        // 单细胞分析
        path: "SingleCellProcess_D",
        name: "SingleCellProcess_D",
        component: () => import("@/views/AdvancedAnalysis/heatMap/SingleCellProcess_D"),
        meta: {

        }
    },
    {
        // 相关性热图系列
        path: "CorrelationHeatMapSeries",
        name: "CorrelationHeatMapSeries",
        component: () => import("@/views/AdvancedAnalysis/heatMap/CorrelationHeatMapSeries"),
        meta: {

        }
    },
    {
        // 聚类热图
        path: "GroupHeatMap",
        name: "GroupHeatMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/GroupHeatMap"),
        meta: {

        }
    },
    {
        // 分组热图
        path: "GroupingHeatMap",
        name: "GroupingHeatMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/GroupingHeatMap"),
        meta: {

        }
    },
    {
        // 组合热图
        path: "CombinationHeatMap",
        name: "CombinationHeatMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/CombinationHeatMap"),
        meta: {

        }
    },
    {
        // 环形热图
        path: "annularHeatMap",
        name: "annularHeatMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/annularHeatMap"),
        meta: {

        }
    },
    {
        // 网络热图
        path: "NetworkHeatMap",
        name: "NetworkHeatMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/NetworkHeatMap"),
        meta: {

        }
    },
    {
        // 简单相关性热图
        path: "SimpleCorrelationHeatMap",
        name: "SimpleCorrelationHeatMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/SimpleCorrelationHeatMap"),
        meta: {

        }
    },
    {
        // 相关性聚类标记热图
        path: "CorrelationCLHeatMap",
        name: "CorrelationCLHeatMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/CorrelationCLHeatMap"),
        meta: {

        }
    },
    {
        // 相关性热图
        path: "CorrelationHeatMap",
        name: "CorrelationHeatMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/CorrelationHeatMap"),
        meta: {

        }
    },
    {
        // 相关系数三角热图
        path: "correlationCoefficientTriangleHeatMap",
        name: "correlationCoefficientTriangleHeatMap",
        component: () =>
            import("@/views/AdvancedAnalysis/heatMap/correlationCoefficientTriangleHeatMap"),
        meta: {

        }
    },
    {
        // 互作热图
        path: "ReciprocalHeatMap",
        name: "ReciprocalHeatMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/ReciprocalHeatMap"),
        meta: {

        }
    },
    {
        // 气泡热图（等差数列比例）
        path: "BubbleArithmeticProgression",
        name: "BubbleArithmeticProgression",
        component: () => import("@/views/AdvancedAnalysis/heatMap/BubbleArithmeticProgression"),
        meta: {

        }
    },
    {
        // 气泡热图
        path: "BubbleHeatMap",
        name: "BubbleHeatMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/BubbleHeatMap"),
        meta: {

        }
    },
    {
        // 多组热图
        path: "manyHeatMaps",
        name: "manyHeatMaps",
        component: () => import("@/views/AdvancedAnalysis/heatMap/manyHeatMaps"),
        meta: {

        }
    },
    {
        // 日历热图
        path: "CalendarHeatMap",
        name: "CalendarHeatMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/CalendarHeatMap"),
        meta: {

        }
    },

    {
        // T检验热图
        path: "TTestHeatMap",
        name: "TTestHeatMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/TTestHeatMap"),
        meta: {

        }
    },
    {
        // 差异箱线图
        path: "DifferenceBoxplot",
        name: "DifferenceBoxplot",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/DifferenceBoxplot"),
        meta: {

        }
    },
    {
        // 趋势云雨图
        path: "TrendRain",
        name: "TrendRain",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/TrendRain"),
        meta: {

        }
    },
    {
        // 趋势小提琴图
        path: "TrendViolin",
        name: "TrendViolin",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/TrendViolin"),
        meta: {

        }
    },
    {
        // 山峦图
        path: "WaistViolin",
        name: "WaistViolin",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/WaistViolin"),
        meta: {

        }
    },
    {
        // 腰线小提琴图
        path: "MountainMap",
        name: "MountainMap",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/MountainMap"),
        meta: {

        }
    },
    {
        // 云雨图
        path: "Nephelogram",
        name: "Nephelogram",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/Nephelogram"),
        meta: {

        }
    },
    {
        // 横版小提琴图
        path: "AbscissaViolin",
        name: "AbscissaViolin",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/AbscissaViolin"),
        meta: {

        }
    },
    {
        // 分布图
        path: "ViolinDistributionMap",
        name: "ViolinDistributionMap",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/ViolinDistributionMap"),
        meta: {

        }
    },
    {
        // 双轴箱线密度图
        path: "CoreDensityCurveBoxplotScatter",
        name: "CoreDensityCurveBoxplotScatter",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/CoreDensityCurveBoxplotScatter"),
        meta: {

        }
    },
    {
        // 环形分组云雨图
        path: "RingGroupingNephelogram",
        name: "RingGroupingNephelogram",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/RingGroupingNephelogram"),
        meta: {

        }
    },
    {
        // 两组配对云雨图
        path: "TwoGroupPairingNephelogram",
        name: "TwoGroupPairingNephelogram",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/TwoGroupPairingNephelogram"),
        meta: {

        }
    },
    {
        // 多组配对云雨图
        path: "multigroupPairMakingLove",
        name: "multigroupPairMakingLove",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/multigroupPairMakingLove"),
        meta: {

        }
    },
    {
        // 配对云雨图
        path: "PairedCloudAndRain",
        name: "PairedCloudAndRain",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/PairedCloudAndRain"),
        meta: {

        }
    },
    {
        // 气泡小提琴图
        path: "BubbleViolinDiagram",
        name: "BubbleViolinDiagram",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/BubbleViolinDiagram"),
        meta: {

        }
    },
    {
        // 多行多列小提琴图
        path: "MultiRowViolin",
        name: "MultiRowViolin",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/MultiRowViolin"),
        meta: {

        }
    },
    {
        // 小提琴图
        path: "ComparisonViolinPlot",
        name: "ComparisonViolinPlot",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/ComparisonViolinPlot"),
        meta: {

        }
    },
    {
        //分半小提琴图
        path: "SplitHalfViolinPlot",
        name: "SplitHalfViolinPlot",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/SplitHalfViolinPlot"),
        meta: {

        }
    },
    {
        //豆荚图
        path: "Pod",
        name: "Pod",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/Pod"),
        meta: {

        }
    },
    {
        //半面小提琴图
        path: "HalfViolinPlot",
        name: "HalfViolinPlot",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/HalfViolinPlot"),
        meta: {

        }
    },
    {
        //蜂巢小提琴图
        path: "BeehiveViolin",
        name: "BeehiveViolin",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/BeehiveViolin"),
        meta: {

        }
    },
    {
        //密度图-直方图
        path: "DensitySquare",
        name: "DensitySquare",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/DensitySquare"),
        meta: {

        }
    },
    {
        // 韦恩图
        path: "VennDiagram",
        name: "VennDiagram",
        component: () => import("@/views/AdvancedAnalysis/VennDiagram/VennDiagram"),
        meta: {

        }
    },
    {
        // 数据降维
        path: "DataDimensionReduction",
        name: "DataDimensionReduction",
        component: () =>
            import("@/views/AdvancedAnalysis/DataDimensionReduction/DataDimensionReduction"),
        meta: {
            title: "数据降维",
        },
    },
    {
        // 分组环状箱线图
        path: "GroupingRingBoxplot",
        name: "GroupingRingBoxplot",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/GroupingRingBoxplot"),
        meta: {

        }
    },
    {
        // 矩阵星标柱状图
        path: "MatrixStarBar",
        name: "MatrixStarBar",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/MatrixStarBar"),
        meta: {

        }
    },
    {
        // 矩阵差异箱线图
        path: "MatrixDifferentialBar",
        name: "MatrixDifferentialBar",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/MatrixDifferentialBar"),
        meta: {

        }
    },
    {
        // 矩阵差异箱线图
        path: "MatrixDifferentialBoxplot",
        name: "MatrixDifferentialBoxplot",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/MatrixDifferentialBoxplot"),
        meta: {

        }
    },
    {
        // 矩阵差异小提琴图
        path: "MatrixDifferentialViolin",
        name: "MatrixDifferentialViolin",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/MatrixDifferentialViolin"),
        meta: {

        }
    },
    {
        // 嵌套字母柱状图（指数比例）
        path: "ExponentNestedLetterBar",
        name: "ExponentNestedLetterBar",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/ExponentNestedLetterBar"),
        meta: {

        }
    },
    {
        // 嵌套字母柱状图
        path: "NestedLetterBar",
        name: "NestedLetterBar",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/NestedLetterBar"),
        meta: {

        }
    },
    {
        // T检验柱状图
        path: "TTestBar",
        name: "TTestBar",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/TTestBar"),
        meta: {

        }
    },
    {
        // 火山图
        path: "VolcanoPlot",
        name: "VolcanoPlot",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/VolcanoPlot"),
        meta: {

        }
    },
    {
        // 渐变火山图
        path: "GradientVolcano",
        name: "GradientVolcano",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/GradientVolcano"),
        meta: {

        }
    },
    {
        // MA火山图
        path: "MAVolcanoMap",
        name: "MAVolcanoMap",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/MAVolcanoMap"),
        meta: {

        }
    },
    {
        // 多组环形火山图
        path: "multigroupLoopVolcanoPlot",
        name: "multigroupLoopVolcanoPlot",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/multigroupLoopVolcanoPlot"),
        meta: {

        }
    },
    {
        // 多组火山图
        path: "MultigroupVolcanoPlot",
        name: "MultigroupVolcanoPlot",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/MultigroupVolcanoPlot"),
        meta: {

        }
    },
    {
        // 事后检验柱状图
        path: "PostTestBar",
        name: "PostTestBar",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/PostTestBar"),
        meta: {

        }
    },
    {
        // anosim分析
        path: "anosimAnalyse",
        name: "anosimAnalyse",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/anosimAnalyse"),
        meta: {

        }
    },
    {
        // adonis分析
        path: "AdonisAnalyse",
        name: "AdonisAnalyse",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/AdonisAnalyse"),
        meta: {

        }
    },
    {
        // PR
        path: "PRAnalyse",
        name: "PRAnalyse",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/PRAnalyse"),
        meta: {

        }
    },
    {
        // 丰富等级曲线
        path: "RankAboundance",
        name: "RankAboundance",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/RankAboundance"),
        meta: {

        }
    },
    {
        // 误差柱状图+带状折线图
        path: "ErrorBandedContourLines",
        name: "ErrorBandedContourLines",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/ErrorBandedContourLines"),
        meta: {

        }
    },
    {
        // 带状误差线图
        path: "BandErrorMap",
        name: "BandErrorMap",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/BandErrorMap"),
        meta: {

        }
    },
    {
        // 差异折线图
        path: "DifferencePolyline",
        name: "DifferencePolyline",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/DifferencePolyline"),
        meta: {

        }
    },

    {
        // 差异校准曲线
        path: "DifferentialCalibrationCurve",
        name: "DifferentialCalibrationCurve",
        component: () =>
            import("@/views/AdvancedAnalysis/DifferenceTest/DifferentialCalibrationCurve"),
        meta: {

        }
    },
    {
        // 两组显著性折线图
        path: "TwoSetsOfSignificanceLinePlots",
        name: "TwoSetsOfSignificanceLinePlots",
        component: () =>
            import("@/views/AdvancedAnalysis/DifferenceTest/TwoSetsOfSignificanceLinePlots"),
        meta: {

        }
    },
    {
        // 多组显著性折线图
        path: "MultipleLinesOfSignificance",
        name: "MultipleLinesOfSignificance",
        component: () =>
            import("@/views/AdvancedAnalysis/DifferenceTest/MultipleLinesOfSignificance"),
        meta: {

        }
    },
    {
        // 线柱组合图
        path: "ComboLineColumn",
        name: "ComboLineColumn",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/ComboLineColumn"),
        meta: {

        }
    },
    {
        // 组合差异箱线图
        path: "CombinationDifferenceBoxPlots",
        name: "CombinationDifferenceBoxPlots",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/CombinationDifferenceBoxPlots"),
        meta: {

        }
    },
    {
        // 误差环状分组堆积柱状图
        path: "DifferenceRingGroupStackingBar",
        name: "DifferenceRingGroupStackingBar",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/DifferenceRingGroupStackingBar"),
        meta: {

        }
    },
    {
        // 半边箱线图
        path: "HalfBoxDiagram",
        name: "HalfBoxDiagram",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/HalfBoxDiagram"),
        meta: {

        }
    },
    {
        // 多组字母标识柱状图
        path: "MultigroupLetterIdentifyBar",
        name: "MultigroupLetterIdentifyBar",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/MultigroupLetterIdentifyBar"),
        meta: {

        }
    },
    {
        // 对照差异柱状图）
        path: "ControlDifferencebar",
        name: "ControlDifferencebar",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/ControlDifferencebar"),
        meta: {

        }
    },
    {
        // 差异柱状图（指数比例）
        path: "DifferenceBarMyspic",
        name: "DifferenceBarMyspic",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/DifferenceBarMyspic"),
        meta: {

        }
    },
    {
        // 环状差异柱状图
        path: "RingDifferenceBar",
        name: "RingDifferenceBar",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/RingDifferenceBar"),
        meta: {

        }
    },
    {
        // PLSDA
        path: "PLSDA",
        name: "PLSDA",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/PLSDA"),
        meta: {

        }
    },
    {
        // LassoS
        path: "LassoS",
        name: "LassoS",
        component: () => import("@/views/AdvancedAnalysis/RegressionAnalysis/LassoS"),
        meta: {

        }
    },
    {
        // LassoS_svg
        path: "LassoS_svg",
        name: "LassoS_svg",
        component: () => import("@/views/AdvancedAnalysis/RegressionAnalysis/LassoS_svg"),
        meta: {

        }
    },
    {
        // edgeR
        path: "edgeR",
        name: "edgeR",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/edgeR"),
        meta: {

        }
    },
    {
        // PCOA
        path: "PCOA",
        name: "PCOA",
        component: () => import("@/views/AdvancedAnalysis/DataDimensionReduction/PCOA"),
        meta: {

        }
    },
    {
        // PCA
        path: "PCA",
        name: "PCA",
        component: () => import("@/views/AdvancedAnalysis/DataDimensionReduction/PCA"),
        meta: {

        }
    },
    {
        // 质心PCoA
        path: "CenterOfMassPCOA",
        name: "CenterOfMassPCOA",
        component: () => import("@/views/AdvancedAnalysis/DataDimensionReduction/CenterOfMassPCOA"),
        meta: {

        }
    },
    {
        // 质心PCA
        path: "CenterOfMassPCA",
        name: "CenterOfMassPCA",
        component: () => import("@/views/AdvancedAnalysis/DataDimensionReduction/CenterOfMassPCA"),
        meta: {

        }
    },
    {
        // 边际PCA
        path: "BoundaryPCA",
        name: "BoundaryPCA",
        component: () => import("@/views/AdvancedAnalysis/DataDimensionReduction/BoundaryPCA"),
        meta: {

        }
    },
    {
        // NMDS+PERMANOVA分析
        path: "NMDSPERMANOVAAnalysis",
        name: "NMDSPERMANOVAAnalysis",
        component: () => import("@/views/AdvancedAnalysis/DataDimensionReduction/NMDSPERMANOVAAnalysis"),
        meta: {

        }
    },
    {
        // NMDS
        path: "NMDS",
        name: "NMDS",
        component: () => import("@/views/AdvancedAnalysis/DataDimensionReduction/NMDS"),
        meta: {

        }
    },
    {
        // 相关性九象限图（p值)
        path: "CorrelationNineQuadrants-P",
        name: "CorrelationNineQuadrants-P",
        component: () => import("@/views/AdvancedAnalysis/correlationAnalysis/CorrelationNineQuadrants-P"),
        meta: {

        }
    },
    {
        // 关联桑吉图
        path: "AssociatedSangittu",
        name: "AssociatedSangittu",
        component: () => import("@/views/AdvancedAnalysis/correlationAnalysis/AssociatedSangittu"),
        meta: {

        }
    },
    {
        // 多组关联桑吉图
        path: "MultigroupAssociatedSangittu",
        name: "MultigroupAssociatedSangittu",
        component: () => import("@/views/AdvancedAnalysis/correlationAnalysis/MultigroupAssociatedSangittu"),
        meta: {

        }
    },
    {
        // 相关性散点图
        path: "CorrelationScatterPlot",
        name: "CorrelationScatterPlot",
        component: () => import("@/views/AdvancedAnalysis/correlationAnalysis/CorrelationScatterPlot"),
        meta: {

        }
    },
    {
        // O2PLS
        path: "O2PLS",
        name: "O2PLS",
        component: () => import("@/views/AdvancedAnalysis/correlationAnalysis/O2PLS"),
        meta: {

        }
    },
    {
        // Zsocre
        path: "Zsocre",
        name: "Zsocre",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/Zsocre"),
        meta: {

        }
    },
    {
        // 九象限图
        path: "NineQuadrantDiagram",
        name: "NineQuadrantDiagram",
        component: () =>
            import("@/views/AdvancedAnalysis/correlationAnalysis/NineQuadrantDiagram"),
        meta: {

        }
    },
    {
        // 关联九象限图
        path: "AssociatedNineQuadrants",
        name: "AssociatedNineQuadrants",
        component: () =>
            import("@/views/AdvancedAnalysis/correlationAnalysis/AssociatedNineQuadrants"),
        meta: {

        }
    },
    {
        // 多功能差异检验图
        path: "MultifunctionalDifferenceTest",
        name: "MultifunctionalDifferenceTest",
        component: () =>
            import("@/views/AdvancedAnalysis/DifferenceTest/MultifunctionalDifferenceTest"),
        meta: {

        }
    },
    {
        //字母标识箱线图
        path: "LettersIdentifyBbox",
        name: "LettersIdentifyBbox",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/LettersIdentifyBbox"),
        meta: {

        }
    },
    {
        //差异趋势图
        path: "DifferenceTrend",
        name: "DifferenceTrend",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/DifferenceTrend"),
        meta: {

        }
    },
    {
        //字母标识柱形图
        path: "LetterMarkDiagram",
        name: "LetterMarkDiagram",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/LetterMarkDiagram"),
        meta: {

        }
    },
    {
        //字母标识柱形图
        path: "MatrixLetterIdentification",
        name: "MatrixLetterIdentification",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/MatrixLetterIdentification"),
        meta: {

        }
    },
    // 矩阵多组线性回归
    {
        path: "MatrixMultigroupLinearRegression",
        name: "MatrixMultigroupLinearRegression",
        component: () => import("@/views/AdvancedAnalysis/RegressionAnalysis/MatrixMultigroupLinearRegression"),
        meta: {

        },
    },
    // 矩阵线性回归
    {
        path: "MatrixLinearRegression",
        name: "MatrixLinearRegression",
        component: () => import("@/views/AdvancedAnalysis/RegressionAnalysis/MatrixLinearRegression"),
        meta: {

        },
    },
    // 边界柱状线性回归散点图
    {
        path: "BoundaryBarRegressScatter",
        name: "BoundaryBarRegressScatter",
        component: () => import("@/views/AdvancedAnalysis/RegressionAnalysis/BoundaryBarRegressScatter"),
        meta: {

        },
    },
    // 线性回归
    {
        path: "MultipleLinearRegression",
        name: "MultipleLinearRegression",
        component: () => import("@/views/AdvancedAnalysis/RegressionAnalysis/MultipleLinearRegression"),
        meta: {

        },
    },
    // 线性回归
    {
        path: "linearRegression",
        name: "linearRegression",
        component: () => import("@/views/AdvancedAnalysis/RegressionAnalysis/linearRegression"),
        meta: {

        },
    },
    // 岭回归
    {
        path: "RidgeRegression",
        name: "RidgeRegression",
        component: () => import("@/views/AdvancedAnalysis/RegressionAnalysis/RidgeRegression"),
        meta: {

        },
    },
    // 岭回归路径图
    {
        path: "RidgeRPathMap",
        name: "RidgeRPathMap",
        component: () => import("@/views/AdvancedAnalysis/RegressionAnalysis/RidgeRPathMap"),
        meta: {

        },
    },
    // 岭回归（交叉验证曲线）
    {
        path: "RidgeRCrossValidationCurve",
        name: "RidgeRCrossValidationCurve",
        component: () => import("@/views/AdvancedAnalysis/RegressionAnalysis/RidgeRCrossValidationCurve"),
        meta: {

        },
    },

    //UpSet韦恩图
    {
        path: "UpSetVennDetail",
        name: "UpSetVennDetail",
        component: () => import("@/views/AdvancedAnalysis/VennDiagram/UpSetVennDetail"),
        meta: {
            title: "UpSet韦恩图",

        },
    },
    //Flower韦恩图
    {
        path: "FlowerVennDetail",
        name: "FlowerVennDetail",
        component: () => import("@/views/AdvancedAnalysis/VennDiagram/FlowerVennDetail"),
        meta: {
            title: "Flower韦恩图",

        },
    },
    //Edwards韦恩图
    {
        path: "EdwardsVennDetail",
        name: "EdwardsVennDetail",
        component: () => import("@/views/AdvancedAnalysis/VennDiagram/EdwardsVennDetail"),
        meta: {
            title: "Edwards韦恩图",

        },
    },
    //Karnaugh韦恩图
    {
        path: "KarnaughVennDetail",
        name: "KarnaughVennDetail",
        component: () => import("@/views/AdvancedAnalysis/VennDiagram/KarnaughVennDetail"),
        meta: {
            title: "Karnaugh韦恩图",

        },
    },
    //渐变韦恩图
    {
        path: "VennGradientDetail",
        name: "VennGradientDetail",
        component: () => import("@/views/AdvancedAnalysis/VennDiagram/VennGradientDetail"),
        meta: {
            title: "渐变韦恩图",

        },
    },
    //Classic韦恩图
    {
        path: "ClassicVennDetail",
        name: "ClassicVennDetail",
        component: () => import("@/views/AdvancedAnalysis/VennDiagram/ClassicVennDetail"),
        meta: {
            title: "Classic韦恩图",

        },
    },
    //韦恩饼图
    {
        path: "VennPieDetail",
        name: "VennPieDetail",
        component: () => import("@/views/AdvancedAnalysis/VennDiagram/VennPieDetail"),
        meta: {
            title: "韦恩饼图",

        },
    },
    //NetWork韦恩图
    {
        path: "NetWorkVennDetail",
        name: "NetWorkVennDetail",
        component: () => import("@/views/AdvancedAnalysis/VennDiagram/NetWorkVennDetail"),
        meta: {
            title: "NetWork韦恩图",

        },
    },


    //比例韦恩图
    {
        path: "PieWienRatioDetail",
        name: "PieWienRatioDetail",
        component: () => import("@/views/AdvancedAnalysis/VennDiagram/PieWienRatioDetail"),
        meta: {
            title: "比例韦恩图",

        },
    },

    //比例韦恩图2
    {
        path: "ProportionVennDetail",
        name: "ProportionVennDetail",
        component: () => import("@/views/AdvancedAnalysis/VennDiagram/ProportionVennDetail"),
        meta: {
            title: "比例韦恩图",

        },
    },

    //花瓣韦恩图
    {
        path: "PetalVennDetail",
        name: "PetalVennDetail",
        component: () => import("@/views/AdvancedAnalysis/VennDiagram/PetalVennDetail"),
        meta: {
            title: "花瓣韦恩图",

        },
    },
    //七组花瓣韦恩图
    {
        path: "SevenPetalVennDetail",
        name: "SevenPetalVennDetail",
        component: () => import("@/views/AdvancedAnalysis/VennDiagram/SevenPetalVennDetail"),
        meta: {
            title: "七组花瓣韦恩图",

        },
    },
    //八组花瓣韦恩图
    {
        path: "eightPetalVennDetail",
        name: "eightPetalVennDetail",
        component: () => import("@/views/AdvancedAnalysis/VennDiagram/eightPetalVennDetail"),
        meta: {
            title: "八组花瓣韦恩图",

        },
    },
    //六组花瓣韦恩图
    {
        path: "SixPetalVennDetail",
        name: "SixPetalVennDetail",
        component: () => import("@/views/AdvancedAnalysis/VennDiagram/SixPetalVennDetail"),
        meta: {
            title: "六组花瓣韦恩图",

        },
    },
    //六组花瓣韦恩图
    {
        path: "FivePetalVennDetail",
        name: "FivePetalVennDetail",
        component: () => import("@/views/AdvancedAnalysis/VennDiagram/FivePetalVennDetail"),
        meta: {
            title: "六组花瓣韦恩图",

        },
    },
    //配对检验箱线图
    {
        path: "PairTestBoxplots",
        name: "PairTestBoxplots",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/PairTestBoxplotsDetail"),
        meta: {
            title: "配对检验箱线图",

        },
    },
    //简易配对图
    {
        path: "EasyPairingDetail",
        name: "EasyPairingDetail",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/EasyPairingDetail"),
        meta: {
            title: "简易配对图",

        },
    },
    //Euler韦恩图
    {
        path: "VennPercentageDetail",
        name: "VennPercentageDetail",
        component: () => import("@/views/AdvancedAnalysis/VennDiagram/VennPercentageDetail"),
        meta: {
            title: "Euler韦恩图",

        },
    },
    //堆叠韦恩图
    {
        path: "VennStackDetail",
        name: "VennStackDetail",
        component: () => import("@/views/AdvancedAnalysis/VennDiagram/VennStackDetail"),
        meta: {
            title: "堆叠韦恩图",

        },
    },

    // 生存分析
    // 森林图
    {
        path: "ForestMap",
        name: "ForestMap",
        component: () =>
            import("@/views/AdvancedAnalysis/SurvivalAnalysis/ForestMap"),
        meta: {

        },
    },
    // 列线图
    {
        path: "Nomogram",
        name: "Nomogram",
        component: () =>
            import("@/views/AdvancedAnalysis/SurvivalAnalysis/Nomogram"),
        meta: {

        },
    },
    // 风险评估三联图
    {
        path: "RiskAssessmentTrigram",
        name: "RiskAssessmentTrigram",
        component: () =>
            import("@/views/AdvancedAnalysis/SurvivalAnalysis/RiskAssessmentTrigram"),
        meta: {

        },
    },
    // 其他
    // 丰度排序散点图
    {
        path: "SortScattersDetail",
        name: "SortScattersDetail",
        component: () => import("@/views/AdvancedAnalysis/rests/SortScattersDetail"),
        meta: {

        },
    },
    // 其他
    // 多重变量相关性检测堆积柱图
    {
        path: "BarCorrelationDetail",
        name: "BarCorrelationDetail",
        component: () => import("@/views/AdvancedAnalysis/rests/BarCorrelationDetail"),
        meta: {

        },
    },
    // 矩阵柱状山峦图
    {
        path: "MatrixMountainsBar",
        name: "MatrixMountainsBar",
        component: () => import("@/views/AdvancedAnalysis/rests/MatrixMountainsBar"),
        meta: {

        },
    },
    // 其他
    // 正态分布检验P-P图
    {
        path: "NormalDistributionTest-P",
        name: "NormalDistributionTest-P",
        component: () => import("@/views/AdvancedAnalysis/rests/NormalDistributionTest-P"),
        meta: {

        },
    },
    // 正态分布检验Q-Q图
    {
        path: "NormalDistributionTest-Q",
        name: "NormalDistributionTest-Q",
        component: () => import("@/views/AdvancedAnalysis/rests/NormalDistributionTest-Q"),
        meta: {

        },
    },
    // 频次直方图
    {
        path: "frequencyHistogram",
        name: "frequencyHistogram",
        component: () => import("@/views/AdvancedAnalysis/rests/frequencyHistogram"),
        meta: {

        },
    },
    // 正态分布检验直方图
    {
        path: "NormalDistributionTest-Histogram",
        name: "NormalDistributionTest-Histogram",
        component: () => import("@/views/AdvancedAnalysis/rests/NormalDistributionTest-Histogram"),
        meta: {

        },
    },
    // 序列标识图
    {
        path: "SequenceIdentification",
        name: "SequenceIdentification",
        component: () => import("@/views/AdvancedAnalysis/rests/SequenceIdentification"),
        meta: {

        },
    },
    // 差异表达雷达图
    {
        path: "DifferentialRepresentationRadar",
        name: "DifferentialRepresentationRadar",
        component: () => import("@/views/AdvancedAnalysis/rests/DifferentialRepresentationRadar"),
        meta: {

        },
    },
    // 方形曼哈顿图
    {
        path: "SquareManhattan",
        name: "SquareManhattan",
        component: () => import("@/views/AdvancedAnalysis/rests/SquareManhattan"),
        meta: {

        },
    },
    // 多轨方形曼哈顿图
    {
        path: "MultiTrackSquareManhattan",
        name: "MultiTrackSquareManhattan",
        component: () => import("@/views/AdvancedAnalysis/rests/MultiTrackSquareManhattan"),
        meta: {

        },
    },
    // 环形曼哈顿图
    {
        path: "CircularManhattan",
        name: "CircularManhattan",
        component: () => import("@/views/AdvancedAnalysis/rests/CircularManhattan"),
        meta: {

        },
    },
    // SNP密度图
    {
        path: "SNPDensityMap",
        name: "SNPDensityMap",
        component: () => import("@/views/AdvancedAnalysis/rests/SNPDensityMap"),
        meta: {

        },
    },
    // SNP密度图
    {
        path: "GeneAssociation-Q",
        name: "GeneAssociation-Q",
        component: () => import("@/views/AdvancedAnalysis/rests/GeneAssociation-Q"),
        meta: {

        },
    },
    {
        path: "SublevelSplineDetail",
        name: "SublevelSplineDetail",
        component: () => import("@/views/AdvancedAnalysis/rests/SublevelSplineDetail"),
        meta: {
            title: '分段多项式样条回归',
        },
    },
    {
        path: "funnelChart",
        name: "funnelChart",
        component: () => import("@/views/AdvancedAnalysis/rests/funnelChart"),
        meta: {
            title: 'meta漏斗图',

        }
    },
    {
        path: 'timeSeriesAnalysis',
        name: 'timeSeriesAnalysis',
        component: () => import('@/views/AdvancedAnalysis/rests/timeSeriesAnalysis'),
        meta: {
            title: '时间序列分析',

        }
    },

    {
        path: 'modelChange',
        name: 'modelChange',
        component: () => import('@/views/AdvancedAnalysis/machineLearning/modelChange'),
        meta: {
            title: '机器学习模型筛选',

        }
    },
    {
        path: 'SVMREFFeaturesDetail',
        name: 'SVMREFFeaturesDetail',
        component: () => import('@/views/AdvancedAnalysis/machineLearning/SVMREFFeaturesDetail'),
        meta: {
            title: 'SVM-REF特征筛选',
        }
    },

    {
        path: "polarViolin",
        name: "polarViolin",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/polarViolin"),
        meta: {
            title: "极坐标小提琴图",

        },
    },
    {
        path: "pairedDumbbell",
        name: "pairedDumbbell",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/pairedDumbbell"),
        meta: {
            title: "配对哑铃组合图",

        },
    },
    {
        path: "timeSeriesTrend",
        name: "timeSeriesTrend",
        component: () => import("@/views/AdvancedAnalysis/rests/timeSeriesTrend"),
        meta: {
            title: "时间序列趋势图",

        },
    },
    //DCA曲线
    {
        path: "DCAcurveDetail",
        name: "DCAcurveDetail",
        component: () => import("@/views/AdvancedAnalysis/rests/DCAcurveDetail"),
        meta: {
            title: "DCA曲线",

        },
    },
    {
        path: "cubicSpline",
        name: "cubicSpline",
        component: () => import("@/views/AdvancedAnalysis/SurvivalAnalysis/cubicSpline"),
        meta: {
            title: "限制性立方样条图(RCS)",

        },
    },
    //C-index曲线
    {
        path: "CindexCurveDetail",
        name: "CindexCurveDetail",
        component: () => import("@/views/AdvancedAnalysis/SurvivalAnalysis/CindexCurveDetail"),
        meta: {
            title: "C-index曲线",

        },
    },
    //亚组森林图
    {
        path: "SubgroupForestsDetail",
        name: "SubgroupForestsDetail",
        component: () => import("@/views/AdvancedAnalysis/SurvivalAnalysis/SubgroupForestsDetail"),
        meta: {
            title: "亚组森林图",

        },
    },
    {
        path: "groupCubicSpline",
        name: "groupCubicSpline",
        component: () => import("@/views/AdvancedAnalysis/SurvivalAnalysis/groupCubicSpline"),
        meta: {
            title: "分组限制性立方样条图(GroupedRCS)",

        },
    },
    {
        path: "singleCox",
        name: "singleCox",
        component: () => import("@/views/AdvancedAnalysis/SurvivalAnalysis/singleCox"),
    },
    {
        path: "moreCox",
        name: "moreCox",
        component: () => import("@/views/AdvancedAnalysis/SurvivalAnalysis/moreCox"),
    },
    {
        path: "limma",
        name: "limma",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/limma"),
    },
    {
        path: "GEO",
        name: "GEO",
        component: () => import("@/views/tableFormat/GEO"),
    },
    {
        path: "differenceAnalysis",
        name: "differenceAnalysis",
        component: () => import("@/views/differenceAnalysis"),
        meta: {
            title: "统计学分析",
            type: '高级分析'
        },
    },

    {
        path: "enrichmentAnalysisCharts",
        name: "enrichmentAnalysisCharts",
        component: () =>
            import("@/views/AdvancedAnalysis/enrichmentAnalysis/enrichmentAnalysisCharts"),
        meta: {
            title: "富集分析",
            type: '高级分析'
        },
    },
    {
        // 富集圈图
        path: "EnrichmentCycleDiagram",
        name: "EnrichmentCycleDiagram",
        component: () =>
            import("@/views/AdvancedAnalysis/enrichmentAnalysis/EnrichmentCycleDiagram"),
        meta: {
            title: "富集圈图",
            type: '高级分析'
        },
    },
    {
        // 富集热图
        path: "EnrichmentHeatMap",
        name: "EnrichmentHeatMap",
        component: () =>
            import("@/views/AdvancedAnalysis/enrichmentAnalysis/EnrichmentHeatMap"),
        meta: {
            title: "富集圈图",
            type: '高级分析'
        },
    },
    {
        // 相关性棒棒糖图
        path: "CorrelationLollipop",
        name: "CorrelationLollipop",
        component: () =>
            import("@/views/AdvancedAnalysis/enrichmentAnalysis/CorrelationLollipop"),
        meta: {
            title: "相关性棒棒糖图",
            type: '高级分析'
        },
    },
    {
        path: "WeiEnDetail",
        name: "WeiEnDetail",
        component: () => import("@/views/AdvancedAnalysis/VennDiagram/WeiEnDetail"), meta: {
            type: '高级分析'
        }
    },
    // 带边界的散点图
    {
        path: "BoundaryScatter",
        name: "BoundaryScatter",
        component: () => import("@/views/AdvancedAnalysis/RegressionAnalysis/BoundaryScatter"),
        meta: {
            type: '高级分析'
        }
    },

    {
        path: "GEELine",
        name: "GEELine",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/GEELine"),
    },
    {
        path: "arrowPCA",
        name: "arrowPCA",
        component: () => import("@/views/AdvancedAnalysis/DataDimensionReduction/arrowPCA"),
    },
    {
        path: 'KMLine',
        name: 'KMLine',
        component: () => import('@/views/AdvancedAnalysis/SurvivalAnalysis/KMLine'),
    },
    {
        path: 'randomForest',
        name: 'randomForest',
        component: () => import('@/views/AdvancedAnalysis/machineLearning/randomForest'),
    },
    {
        path: 'correlationBar',
        name: 'correlationBar',
        component: () => import('@/views/AdvancedAnalysis/correlationAnalysis/correlationBar'),
    },
    {
        path: 'ridgeMap',
        name: 'ridgeMap',
        component: () => import('@/views/AdvancedAnalysis/areaGraph/ridgeMap'),
    },
    {
        path: '3DSurfaceWave',
        name: '3DSurfaceWave',
        component: () => import('@/views/AdvancedAnalysis/areaGraph/3DSurfaceWave'),
    },
    {
        path: 'calibrationLine',
        name: 'calibrationLine',
        component: () => import('@/views/AdvancedAnalysis/SurvivalAnalysis/calibrationLine'),
    },
    {
        path: "groupVolcano",
        name: "groupVolcano",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/groupVolcano"),
    },
    {
        path: "towCurveVolcano",
        name: "towCurveVolcano",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/towCurveVolcano"),
    },
    {
        path: "groupLettersLogoLine",
        name: "groupLettersLogoLine",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/groupLettersLogoLine"),
    },
    {
        path: "lettersLogoLine",
        name: "lettersLogoLine",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/lettersLogoLine"),
    },
    {
        path: "MDERandomAnalysis",
        name: "MDERandomAnalysis",
        component: () => import("@/views/AdvancedAnalysis/rests/MDERandomAnalysis"),
    },
    {
        path: 'associationNetwork',
        name: 'associationNetwork',
        component: () => import('@/views/AdvancedAnalysis/correlationAnalysis/associationNetwork'),
    },
    {
        path: "geneNetwork_KEGG",
        name: "geneNetwork_KEGG",
        component: () => import("@/views/AdvancedAnalysis/enrichmentAnalysis/geneNetwork_KEGG"),
    },
    {
        path: "geneNetwork_GO",
        name: "geneNetwork_GO",
        component: () => import("@/views/AdvancedAnalysis/enrichmentAnalysis/geneNetwork_GO"),
    },
    {
        path: "subgroupAnalysis",
        name: "subgroupAnalysis",
        component: () => import("@/views/AdvancedAnalysis/SurvivalAnalysis/subgroupAnalysis"),
    },
    {
        path: "ssGSEA",
        name: "ssGSEA",
        component: () => import("@/views/AdvancedAnalysis/enrichmentAnalysis/ssGSEA"),
    },
    {
        path: "enrichmentAnnularHeatmap",
        name: "enrichmentAnnularHeatmap",
        component: () => import("@/views/AdvancedAnalysis/enrichmentAnalysis/enrichmentAnnularHeatmap"),
    },
    {
        path: "twoEdgeRAnalysis",
        name: "twoEdgeRAnalysis",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/twoEdgeRAnalysis"),
    },
    {
        path: "triangularHeatmap",
        name: "triangularHeatmap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/triangularHeatmap"),
    },
    {
        path: "giviti",
        name: "giviti",
        component: () => import("@/views/AdvancedAnalysis/RegressionAnalysis/giviti"),
    },
    {
        path: "cumulativeDistribution",
        name: "cumulativeDistribution",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/cumulativeDistribution"),
    },
    {
        path: "GSEACustom",
        name: "GSEACustom",
        component: () => import("@/views/AdvancedAnalysis/enrichmentAnalysis/GSEACustom"),
    },
    {
        path: "differentialBubble2",
        name: "differentialBubble2",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/differentialBubble2"),
    },
    {
        path: "groupDifferences",
        name: "groupDifferences",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/groupDifferences"),
    },
    {
        path: "correlationROC",
        name: "correlationROC",
        component: () => import("@/views/AdvancedAnalysis/correlationAnalysis/correlationROC"),
    },
    {
        path: "3DridgeMap",
        name: "3DridgeMap",
        component: () => import("@/views/AdvancedAnalysis/areaGraph/3DridgeMap"),
    },
    {
        path: "TimeSeriesCurve",
        name: "TimeSeriesCurve",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/TimeSeriesCurve"),
    },
    {
        path: "TwoFactorVariance",
        name: "TwoFactorVariance",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/TwoFactorVariance"),
    },
    {
        // 多组热图
        path: "manyHeatMaps2",
        name: "manyHeatMaps2",
        component: () => import("@/views/AdvancedAnalysis/heatMap/manyHeatMaps2")
    },
    {
        path: "ROCline",
        name: "ROCline",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/ROC/index"),
    },
    {
        path: "orchardChart",
        name: "orchardChart",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/orchardChart"),
    },
    {
        path: "correlationPie",
        name: "correlationPie",
        component: () => import("@/views/AdvancedAnalysis/correlationAnalysis/correlationPie"),
    },
    {
        path: "Fisher's",
        name: "Fisher's",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/Fisher's"),
    },
    {
        path: "chi_square",
        name: "chi_square",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/chi_square"),
    },
    {
        path: "pairedTestLine",
        name: "pairedTestLine",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/pairedTestLine"),
    },
    {
        path: "enrichmentBubble",
        name: "enrichmentBubble",
        component: () => import("@/views/AdvancedAnalysis/enrichmentAnalysis/enrichmentBubble"),
    },
    {
        path: "groupForest",
        name: "groupForest",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/groupForest"),
    },
    {
        path: "groupError",
        name: "groupError",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/groupError"),
    },
    {
        path: "groupEnrichmentHeatMap",
        name: "groupEnrichmentHeatMap",
        component: () => import("@/views/AdvancedAnalysis/enrichmentAnalysis/groupEnrichmentHeatMap"),
    },
    {
        path: "GEOGSE",
        name: "GEOGSE",
        component: () => import("@/views/tableFormat/GEOGSE"),
    },
    {
        path: "correlationDensityPoint",
        name: "correlationDensityPoint",
        component: () => import("@/views/AdvancedAnalysis/rests/correlationDensityPoint"),
    },
    {
        path: "lollipopHeartMap",
        name: "lollipopHeartMap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/lollipopHeartMap"),
    },
    {
        path: "KEGG_pathway_enrichment_second_class",
        name: "KEGG_pathway_enrichment_second_class",
        component: () => import("@/views/AdvancedAnalysis/enrichmentAnalysis/KEGG_pathway_enrichment_second_class"),
    },
    {
        path: "LOD_Assessment",
        name: "LOD_Assessment",
        component: () => import("@/views/AdvancedAnalysis/rests/LOD_Assessment"),
    },
    {
        path: "EnrichmentHeatMapAndBar",
        name: "EnrichmentHeatMapAndBar",
        component: () => import("@/views/AdvancedAnalysis/enrichmentAnalysis/EnrichmentHeatMapAndBar"),
    },
    {
        path: "spiralHeatmap",
        name: "spiralHeatmap",
        component: () => import("@/views/AdvancedAnalysis/heatMap/spiralHeatmap"),
    },
]
const WithTypes = highAll.map(item => {
    if (!item.meta) {
        // 没有meta对象
        return {
            ...item,
            meta: {
                type: "高级分析",
            },
        };
    }

    // 有meta对象
    return {
        ...item,
        meta: {
            ...item.meta,
            type: "高级分析", // 替换成你想要的类型值
        },
    };
});
export default WithTypes