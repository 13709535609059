<template>
  <!-- 复制数据 -->
  <div class="copyAll">
    <el-tabs
      v-show="isTable"
      class="copy"
      v-model="activeName"
      @tab-click="handleClick"
    >
      <el-tab-pane :label="label[0]" name="first">
        <div class="tips"></div>
        <div class="incre">
          <span class="el-dropdown-link">
            <i class="el-icon-plus" @click="incre(0)"></i>
          </span>
          <span class="el-dropdown-link">
            <i class="el-icon-minus" @click="desc(0)"></i>
          </span>
        </div>
        <div class="all boxAll">
          <div
            class="resizeMe"
            id="testDiv"
            ref="resizeMe1"
            style="overflow-x: hidden; padding-left: 10px"
          >
            <div
              _ngcontent-kyu-c381=""
              class="line"
              ref="line"
              @mousedown="doDown"
            >
              <img
                _ngcontent-kyu-c381=""
                src="../assets/tubiao1.png"
                style="width: 5px; height: 18px"
              />
            </div>
            <!-- 更新 -->
            <div style="height: 100%" id="x-spreadsheet"></div>
          </div>
        </div>
        <el-button
          size="mini"
          type="primary"
          @click="clear(1)"
          style="position: absolute; bottom: 20px; right: 20px"
        >
          清空
        </el-button>
      </el-tab-pane>
      <el-tab-pane :label="label[1]" name="Third">
        <div class="tips"></div>
        <div class="incre">
          <span class="el-dropdown-link">
            <i class="el-icon-plus" @click="incre(1)"></i>
          </span>
          <span class="el-dropdown-link">
            <i class="el-icon-minus" @click="desc(1)"></i>
          </span>
        </div>
        <div class="all boxAll">
          <div
            class="resizeMe"
            id="testDiv"
            ref="resizeMe2"
            style="overflow-x: hidden; padding-left: 10px"
          >
            <div
              _ngcontent-kyu-c381=""
              class="line"
              ref="line"
              @mousedown="doDown"
            >
              <img
                _ngcontent-kyu-c381=""
                src="../assets/tubiao1.png"
                style="width: 5px; height: 18px"
              />
            </div>

            <!-- 更新 -->
            <div style="height: 100%" id="x-spreadsheet1"></div>
          </div>
        </div>
        <el-button
          size="mini"
          type="primary"
          @click="clear(2)"
          style="position: absolute; bottom: 20px; right: 20px"
        >
          清空
        </el-button>
      </el-tab-pane>
    </el-tabs>
    <el-button
      type="success"
      @click="uploadSampleFile"
      style="position: absolute; bottom: 20px"
      @mouseenter.native="handleMouseEnter"
      @mouseleave.native="handleMouseLeave"
      >上传</el-button
    >
    <ul class="uploadTip" v-if="showUploadTip">
      <li>①清空表格数据后通过键盘上Ctrl C+V从本地excel粘贴数据(小数位数过多请转为科学计数后再粘贴)</li>
      <li>
        ② 建议优先通过表格复制粘贴方式上传，文件上传容易因为未知隐藏格式报错
      </li>
    </ul>
  </div>
</template>

<script>
// 新添加
import Spreadsheet from "x-data-spreadsheet";
import "x-data-spreadsheet/dist/locale/zh-cn";
Spreadsheet.locale("zh-cn", window.x_spreadsheet.$messages["zh-cn"]);
import { Loading, Message } from "element-ui";
import * as XLSX from "xlsx";
import bus from "../utils/bus";

export default {
  props: {
    interface: {
      type: String,
    },
    xmldata: {
      type: Object,
    },
    xmldata1: {
      type: Object,
    },
    params: {
      type: Object,
      default: () => ({
        projectname: "",
        file1: null,
        file2: null,
      }),
    },
    isTable: {
      type: Boolean,
      default: true,
    },
    label: {
      type: Array,
      default: () => ["数据一", "数据二"],
    },
  },
  components: {},
  data() {
    return {
      elementWidth: 0,
      x1: null,
      x: null,
      // 鼠标移入上传按钮的时候看是否显示提示词
      showUploadTip: false,
      // 检查是否是vip
      VIP_Num: null,

      activeName: "first",
      theobject: null,
      data1: [],
      loadinginstace: null,
      height: null,
      width: null,
    };
  },
  created() {
    // bus.$on("Send_Vip_Data", (data) => {
    //   // console.log(data);
    //   this.VIP_Num = data.user.vip;
    //   // console.log(this.VIP_Num);
    // });
    // 获取当前页面的 URL
    const currentUrl = window.location.href;
    const encodedUrl = encodeURIComponent(currentUrl);
    this.axios
      .post("https://cnsknowall.com/api/islogin?p=" + encodedUrl, {
        withCredentials: true,
        //  headers: {
        //   "Content-Type": "multipart/form-data",
        // },
      })
      .then((response) => {
        // console.log(response.data); //后台返回的数据
        this.VIP_Num = response.data.user.vip;
        // console.log(this.VIP_Num);
      })
      .catch(() => {
        // console.log(error.response.data)
        this.$message({
          showClose: true,
          message: "请重新登陆",
          type: "error",
        });
      });
  },
  mounted() {
    // 初始化拖拽功能
    document.onmouseup = this.doUp;
    document.onmousemove = this.doMove;
    this.init();
    this.init1();
    window.addEventListener("resize", this.getHeight);
    if (typeof ResizeObserver !== "undefined") {
      this.resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.target === this.$refs.resizeMe1) {
            this.elementWidth = entry.contentRect.width;
            this.handleResize("resizeMe1", entry.contentRect);
          } else if (entry.target === this.$refs.resizeMe2) {
            this.elementWidth = entry.contentRect.width;
            this.handleResize("resizeMe2", entry.contentRect);
          }
        }
      });

      // 监听所有需要的元素
      this.resizeObserver.observe(this.$refs.resizeMe1);
      this.resizeObserver.observe(this.$refs.resizeMe2);

      // 检查是否成功开始观察
      console.log("ResizeObserver started observing elements");
    } else {
      console.error("ResizeObserver is not supported in your browser.");
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getHeight);
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.$refs.resizeMe1);
      this.resizeObserver.unobserve(this.$refs.resizeMe2);
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    // // 创建表格
    // createExcelFile(data, sheetName) {
    //   const ws = XLSX.utils.aoa_to_sheet(data);
    //   const wb = XLSX.utils.book_new();
    //   XLSX.utils.book_append_sheet(wb, ws, sheetName);
    //   return XLSX.write(wb, { bookType: "xlsx", type: "array" });
    // },
    getHeight() {
      if (this.activeName == "first") {
        this.height = document.querySelectorAll(".boxAll")[0].clientHeight;
        this.width = this.$refs.resizeMe1.clientWidth;
      } else {
        this.height = document.querySelectorAll(".boxAll")[1].clientHeight;
        this.width = this.$refs.resizeMe2.clientWidth;
      }
      // console.log(document.querySelectorAll(".boxAll"));
      // this.height = document.querySelector(".boxAll").clientHeight;
      // this.width = document.querySelector(".boxAll").clientWidth;
      // console.log(this.width);
    },
    handleClick(tab) {
      // console.log(tab.index*1+1)
    },
    init() {
      // console.log(this.$props.xmldata);
      const rows10 = this.$props.xmldata;
      let length = Object.keys(rows10).length;
      const that = this;
      this.$nextTick(() => {
        // 在DOM渲染完毕后获取元素的高度
        this.getHeight();
        // this.height = document.querySelector(".boxAll").clientHeight;
        // this.width = this.$refs.all.clientHeight;
        const xs = new Spreadsheet("#x-spreadsheet", {
          // 修改x-spreadsheet默认配置
          showContextmenu: true, // false右键菜单

          showToolbar: false, //隐藏顶部工具栏
          showBottomBar: false, //隐藏底部工具栏
          view: {
            // 设置表格宽高
            height: () => that.height,
            width: () => that.width - 10,
          },
          // 设置行数
          row: {
            len: length > 100000 ? length + 100 : 100000,
            height: 25,
          },
          // 设置列数52407 76387 79984
          col: {
            len: 1000,
            width: 90,
            indexWidth: 40, //行数索引宽度
            minWidth: 60,
          },
          // 修改字体的背景颜色和内容
          // style: {
          //   // 字体颜色框背景 --- 单元格存在内容时改变颜色
          //   bgcolor: "#94d1fa",
          //   // 字体居中
          //   align: "center",
          // },
          style: {
            // 背景颜色
            bgcolor: "#ffffff",
            // 水平对齐方式
            align: "center",
            // 垂直对齐方式
            valign: "middle",
            // 是否需要换行
            textwrap: false,
            // 虚线边框
            strike: false,
            // 下画线
            underline: false,
            // 文字颜色
            color: "#0a0a0a",
            // 字体设置
            font: {
              // 字体
              name: "Helvetica",
              // 字号大小
              size: 10,
              // 是否加粗
              bold: false,
              // 斜体
              italic: false,
            },
          },
        })
          .loadData([{ name: "", rows: rows10 }])
          .change((data) => {
            // 列表数据改变时触发
            // console.log(data);
            this.jData(data);
          });
        this.x = xs;
        xs.validate();
      });
    },
    init1() {
      // console.log(this.$props.xmldata1);
      const rows10 = this.$props.xmldata1;
      let length = Object.keys(rows10).length;
      const that = this;
      this.$nextTick(() => {
        // 在DOM渲染完毕后获取元素的高度
        this.getHeight();
        // this.height = this.$refs.all.clientHeight;
        // this.width = this.$refs.all.clientHeight;
        const xs1 = new Spreadsheet("#x-spreadsheet1", {
          // 修改x-spreadsheet默认配置
          showContextmenu: true, // false右键菜单

          showToolbar: false, //隐藏顶部工具栏
          showBottomBar: false, //隐藏底部工具栏
          view: {
            // 设置表格宽高
            height: () => that.height,
            width: () => that.width - 10,
          },
          // 设置行数
          row: {
            len: length > 100000 ? length + 100 : 100000,
            height: 25,
          },
          // 设置列数52407 76387 79984
          col: {
            len: 1000,
            width: 90,
            indexWidth: 40, //行数索引宽度
            minWidth: 60,
          },
          // 修改字体的背景颜色和内容
          // style: {
          //   // 字体颜色框背景 --- 单元格存在内容时改变颜色
          //   bgcolor: "#94d1fa",
          //   // 字体居中
          //   align: "center",
          // },
          style: {
            // 背景颜色
            bgcolor: "#ffffff",
            // 水平对齐方式
            align: "center",
            // 垂直对齐方式
            valign: "middle",
            // 是否需要换行
            textwrap: false,
            // 虚线边框
            strike: false,
            // 下画线
            underline: false,
            // 文字颜色
            color: "#0a0a0a",
            // 字体设置
            font: {
              // 字体
              name: "Helvetica",
              // 字号大小
              size: 10,
              // 是否加粗
              bold: false,
              // 斜体
              italic: false,
            },
          },
        })
          .loadData([{ name: "", rows: rows10 }])
          .change((data) => {
            // 列表数据改变时触发
            // console.log(data);
            this.jData(data);
          });
        this.x1 = xs1;
        xs1.validate();
      });
    },
    handleResize(element, contentRect) {
      const resizeEvent = new Event("resize");
      window.dispatchEvent(resizeEvent);
      // 处理尺寸变化
      // console.log(`${element} resized:`, contentRect.width, contentRect.height);
      // 根据需要处理每个元素的尺寸变化
    },
    getParentHeight() {
      const parentElement = document.querySelector("#testDiv");
      if (parentElement) {
        return parentElement.offsetHeight; // 获取父元素的高度
      }
      return 550; // 如果没有找到元素，返回默认高度
    },
    // 看是否显示上传时的提示词
    handleMouseEnter() {
      this.showUploadTip = true;
    },
    handleMouseLeave() {
      this.showUploadTip = false;
    },
    jData(data) {
      var odata = [],
        d0 = [],
        d = [];
      Object.keys(data).forEach((k) => {
        if (data[k] instanceof Object) {
          if ("rows" in data[k]) {
            d0 = [];
            Object.keys(data[k].rows).forEach((k1) => {
              if (data[k].rows[k1] instanceof Object) {
                if ("cells" in data[k].rows[k1]) {
                  d = [];
                  Object.keys(data[k].rows[k1].cells).forEach((k2) => {
                    d.push(data[k].rows[k1].cells[k2].text);
                  });
                  d0.push(d);
                }
              }
            });
            odata.push(d0);
          }
        }
      });
      return odata;
    },
    // 上传示例文件
    uploadSampleFile() {
      if (this.VIP_Num == 1 || this.VIP_Num == 2) {
        const file = this.x.getData();
        const ds = this.jData(file);
        const file1 = this.x1.getData();
        console.log(file, file1);
        const ds1 = this.jData(file1);
        console.log(JSON.stringify(ds[0]));
        const file11 = JSON.stringify(ds[0]);
        const file22 = JSON.stringify(ds1[0]);
        // let convertedData = this.$convertStringToNumber(ds[0]);
        // let convertedData1 = this.$convertStringToNumber(ds1[0]);
        // console.log(convertedData, convertedData1);
        //启动 loading 加载
        this.dialogVisible = false;
        let t;
        let xhr = null;
        let time = 60;
        this.loadinginstace = Loading.service({
          lock: true,
          text: "正在处理，请稍后。。。",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.5)",
        });
        t = setInterval(() => {
          time--;
          if (time > 30) {
            switch (true) {
              case time <= 50 && 40 < time:
                this.loadinginstace.text =
                  "您提交的数据量较大，请耐心等待。。。";
                break;
              default:
                break;
            }
          } else {
            clearInterval(t);
            // if (xhr) {
            //     xhr.abort();
            // }
            this.loadinginstace.close();
            this.$confirm("正在处理，可在我的项目里获取结果", "提示", {
              confirmButtonText: "前往我的项目",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                // setTimeout(() => {
                // 设置要跳转的目标路由路径
                this.$router.push("ProjectOverview"); // 替换为你的目标路由路径
                // }, 2000);
              })
              .catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '已取消删除'
                // });
              });
          }
        }, 1000);
        // 上述定时器
        // ds.shift();
        // console.log(convertedData1.length, 1111);

        // const excelBuffer1 = this.createExcelFile(convertedData, "Sheet1");
        // const blob1 = new Blob([excelBuffer1], {
        //   type: "application/octet-stream",
        // });
        // this.params.file1 = new File([blob1], "data.xlsx");
        // if (convertedData1.length !== 0) {
        //   const excelBuffer2 = this.createExcelFile(convertedData1, "Sheet2");
        //   const blob2 = new Blob([excelBuffer2], {
        //     type: "application/octet-stream",
        //   });
        //   this.params.file2 = new File([blob2], "data.xlsx");
        // } else {
        //   this.params.file2 = null;
        // }
        // var formData = new FormData();
        // formData.append("files[]", file11);
        // var formData = new FormData();
        // formData.append("files[]", file22);
        this.params["files[0]"] = file11;
        this.params["files[1]"] = file22;
        // let par = {
        //   'files[]':'A',
        //   'files[]':'B'
        // }
        // console.log(par,par['files[]']);
        this.$post("/upload/" + this.$route.query.pid, this.$props.params)
          .then((response) => {
            // 处理上传成功后的操作
            clearInterval(t);
            this.loadinginstace.close();
            // console.log("上传成功", response);
            if (response.data === "") {
              this.$message({
                showClose: false,
                message: "数据错误,请检查数据格式是否正确",
                type: "error",
              });
            } else {
              this.modalOpen = false;
              if (
                response.data.error == "User Logged in elsewhere" ||
                response.data.error == "User Not Login"
              ) {
                this.$message({
                  showClose: false,
                  message: response.data.error,
                  type: "error",
                });
                this.$router.push("/");
              } else {
                if (response.data.error.length > 0) {
                  this.$message({
                    showClose: false,
                    message: response.data.error,
                    type: "error",
                  });
                } else if (response.data.error.msg) {
                  this.$message({
                    showClose: false,
                    message: response.data.error.msg,
                    type: "error",
                  });
                } else {
                  this.data = response.data;
                  this.$emit("handleSuccess", this.data);
                }
              }
            }
          })
          .catch((error) => {
            clearInterval(t);
            if (xhr) {
              xhr.abort(); // Abort the request if it's still ongoing
            }
            this.loadinginstace.close();
          });
      } else {
        this.$message({
          showClose: true,
          message: "仅限会员使用",
          type: "warning",
        });
        // 使用 setTimeout 设置延时
        setTimeout(() => {
          // 设置要跳转的目标路由路径
          this.$router.push("/PersonalCenter"); // 替换为你的目标路由路径
        }, 2000); // 3000毫秒（即3秒）的延时
      }
    },
    clear(index) {
      if (index == 1) {
        this.x.loadData({});
      } else {
        this.x1.loadData({});
      }

      console.log("表格数据已清除");
    },
    choseExample() {
      // 跳转地址
      // this.$route.push({});
    },

    // 拖拽效果
    getReal(el, type, value) {
      let temp = el;
      while (temp !== null && temp.tagName !== "BODY") {
        if (eval(`temp.${type}`) === value) {
          el = temp;
          return el;
        }
        temp = temp.parentElement;
      }
      return el;
    },
    doDown(event) {
      const el = this.getReal(event.target, "className", "resizeMe");
      if (el === null) return;

      const dir = this.getDirection(el);
      if (dir === "") return;

      this.theobject = {
        el,
        dir,
        grabx: event.clientX,
        graby: event.clientY,
        width: el.offsetWidth,
        height: el.offsetHeight,
        left: el.offsetLeft,
        top: el.offsetTop,
      };

      event.returnValue = false;
      event.cancelBubble = true;
    },
    doUp() {
      if (this.theobject !== null) {
        this.theobject = null;
      }
    },
    doMove(event) {
      if (this.theobject !== null) {
        const { dir, el } = this.theobject;
        const { clientX, clientY } = event;
        const xMin = 420; // The smallest width possible
        const xMax = document.documentElement.clientWidth - 250; // The largest width possible

        if (dir.indexOf("w") !== -1) {
          el.style.left = `${Math.max(
            Math.min(
              this.theobject.left + clientX - this.theobject.grabx,
              this.theobject.left + this.theobject.width - xMin
            ),
            this.theobject.left - xMax + this.theobject.width
          )}px`;
          el.style.width = `${Math.max(
            xMin,
            Math.min(
              this.theobject.width - clientX + this.theobject.grabx,
              xMax
            )
          )}px`;
        }

        event.returnValue = false;
        event.cancelBubble = true;
      }
    },
    getDirection(el) {
      const xPos = event.offsetX;
      const yPos = event.offsetY;
      const offset = 8; // The distance from the edge in pixels
      let dir = "";
      if (xPos < offset) dir += "w";
      return dir;
    }, //
    // 放大表格
    incre(num) {
      let wid = document.querySelectorAll(".resizeMe");
      wid[num].style.width = document.documentElement.clientWidth - 250 + "px";

      const Strlength = wid[num].style.width.length;
      var str1 = Number(wid[num].style.width.substr(0, Strlength - 2));
      this.width = str1;
      console.log(this.width, wid[num].style.width, str1);
    },
    desc(num) {
      let wid = document.querySelectorAll(".resizeMe");
      wid[num].style.width = 420 + "px";
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: content-box !important;
}
::v-deep .el-tab-pane {
  height: 100%;

  #Copy {
    height: 100%;
  }
}

.copyAll {
  height: calc(100% - 70px) !important;

  /* // display: flex; */
  .copy {
    height: 100%;
    overflow: initial;

    .all {
      height: 100%;

      #testDiv {
        width: 420px;
        margin: 0px 0px 0px auto;
        height: 100%;
        .line {
          height: 100%;
          top: 0;
          left: -1px;
          width: 14px;
          position: absolute;
        }
      }
    }

    .tips {
      position: relative;
      top: -48px;
      left: 150px;
      float: left;
      /* // width: 211px; */
      width: 26px;
      display: flex;
      align-items: center;

      .pic {
        height: 26px;
        width: 26px;
      }

      .tit {
        font-size: 12px;
        display: none;
        margin-left: 6px;
        width: 183px;
        position: absolute;
        left: 20px;
        color: red;
      }
    }

    .tips:hover .tit {
      display: block;
    }

    .incre {
      position: relative;
      top: -45px;
      float: right;
      font-size: 20px;
    }

    .desc {
      position: absolute;
      top: -43px;
      right: 80px;
      font-size: 13px;
    }
  }

  button {
    margin-top: 10px;
    cursor: pointer;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }
}

::v-deep .innerTab {
  position: absolute;
  top: 0;
  left: 20px;
  right: 0;
  height: 100%;
  overflow: auto;

  .htRowHeaders {
    height: 100%;

    .ht_master {
      height: 100%;

      .wtHolder {
        height: 100%;
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .ht_master {
    .wtHider {
      width: 100% !important;

      .wtSpreader {
        width: 100%;

        th {
          width: 100px !important;
        }

        td {
          width: 100px !important;
        }

        > .htCore {
          width: 100%;
        }
      }
    }

    .ht_clone_top {
      width: 100% !important;

      .wtHider {
        width: 100% !important;
      }
    }
  }

  .hot-display-license-info {
    opacity: 0;
    height: 0px;
    overflow: hidden;
  }
}
.uploadTip {
  // display: block;
  position: absolute;
  bottom: 6px;
  width: 276px;
  left: 87px;
  font-size: 12px;
  background: #303133;
  list-style: none;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}
// ::v-deep .el-button--success:hover .uploadTip {
//   // display: block ;
//   color: black !important;
// }
/* // ::v-deep .handsontable {
  //   tr{
  //     height: 30px;
  //   }
  //   .relative {
  //     // line-height: 4;
  //     height: calc(100% + 1px);

  //     .rowHeader {
  //       height: 100%;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //     }
  //   }
  // } */

.innerTab .ht_master .wtHolder .innerTab::-webkit-scrollbar {
  /* 对应纵向滚动条的宽度 */
  width: 10px;
  /* 对应横向滚动条的宽度 */
  height: 10px;
}

/* 滚动条上的滚动滑块 */
.ht_master .wtHolder .innerTab::-webkit-scrollbar-thumb {
  background-color: #49b1f5;
  border-radius: 32px;
}

/* 滚动条轨道 */
.ht_master .wtHolder .innerTab::-webkit-scrollbar-track {
  background-color: #dbeffd;
  border-radius: 32px;
}
</style>
<style>
.htMenu {
  height: 300px !important;
  /* left: 1200px !important; */
  width: 220px !important;
  right: 100px !important;
}
.el-tabs--border-card > .el-tabs__header {
  background-color: #765fb1;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item {
  color: #fff;
}
.el-collapse-item__header {
  background: #8fb8d5;
  border-radius: 6px;
  color: #fff;
  padding: 0 10px;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  background: #8fb8d5;
  color: #fff;
}
</style>
<style lang="less" scoped>
// #x-spreadsheet {
//   width: 500px;
//   height: 500px;
//   margin-top: 20px;
//   background-color: #1e253a;

//   /* 深度选择--修改表格背景 */
//   /deep/ .x-spreadsheet {
//     // background-color: #94d1fa;
//     .x-spreadsheet-editor-area {
//       border: none;
//       /* 修改输入框背景色 */
//       textarea {
//         background-color: #94d1fa;
//       }
//     }
//   }
// }
/deep/ .x-spreadsheet {
  height: 100%;
  textarea {
    // width: 97.8px !important;
    // height: 26.8px !important;
    // line-height: 27.8px;
  }
}
// /deep/ .x-spreadsheet-selector-area {
//   width: 100.8px !important;
//   height: 29.8px !important;
// }
// /deep/ .x-spreadsheet-selector .x-spreadsheet-selector-corner {
//   box-sizing: content-box !important;
// }
// /deep/ .x-spreadsheet-toolbar {
//   width: 100% !important;
// }
/deep/ .x-spreadsheet-editor .x-spreadsheet-editor-area textarea {
  box-sizing: content-box !important;
}
</style>