import Vue from "vue"
import VueRouter from "vue-router"
// import Home from './views/Home.vue'
import Login from "@/views/Login.vue"
import HomePage from "@/views/home/HomePage.vue"
import biologicalInformationProcess from './modules/biologicalInformationProcess'
import biologicalInformationAnalysis from './modules/biologicalInformationAnalysis'
import basicAnalysis from "./modules/basicAnalysis"
import highAll from "./modules/highAll"
import Tumorbioinformatics from './modules/Tumorbioinformatics'
import transcriptomeAnalysis from './modules/transcriptomeAnalysis'
import ProteomeAnalysis from './modules/ProteomeAnalysis'
import Metabolome from './modules/Metabolome'
import WGCNA from './modules/WGCNA'
Vue.use(VueRouter)
const routes = [
  {
    path: "/",
    redirect: '/HomePage'
  },
  {
    path: "/HomePage",
    name: "HomePage",
    component: HomePage,
  },
  // {
  //   path: "*",
  //   component: Login,
  // },
  {
    path: "/Login",
    name: "Login",
    component: Login,
  },
  {
    path:'/Navigavion',
    name: "Navigation",
    component:  () => import("@/views/navigationBar/index.vue"),
  },
  {
    path: "/Home",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    redirect: { name: 'BottomContainHot' },
    children: [
      {
        path: "Contain",
        name: "Contain",
        component: () => import("@/views/Contain"),
        redirect: { name: 'BottomContainHot' },
        children: [
          {
            path: "BottomContainAll",
            name: "BottomContainAll",
            component: () => import("@/views/BottomContainAll"),
            meta: {
              title: "全部",
              keepAlive: true, //判断是否缓存 true 为缓存 false 不缓存
              type: '基础分析'
            },
          },
          {
            path: "BottomContainHot",
            name: "BottomContainHot",
            component: () => import("@/views/BottomContainHot"),
            meta: {
              title: "面积图",
              type: '基础分析'
            },
          },
          {
            path: "BottomContainBar",
            name: "BottomContainBar",
            component: () => import("@/views/BottomContainBar"),
            meta: {
              title: "条形图",
              type: '基础分析'
            },
          },
          {
            path: "BottomContainCom",
            name: "BottomContainCom",
            component: () => import("@/views/BottomContainCom"),
            meta: {
              title: "动态交互",
            },
          },
          {
            path: "BottomContainBase",
            name: "BottomContainBase",
            component: () => import("@/views/BottomContainBase"),
            meta: {
              title: "基础绘图",
            },
          },
          {
            path: "BottomContainMap",
            name: "BottomContainMap",
            component: () => import("@/views/BottomContainMap"),
            meta: {
              title: "地图",
              type: '基础分析'
            },
          },
          {
            path: "BottomContainLine",
            name: "BottomContainLine",
            component: () => import("@/views/BottomContainLine"),
            meta: {
              title: "线形图",
              type: '基础分析'
            },
          },
          {
            path: "BottomContainBoxplot",
            name: "BottomContainBoxplot",
            component: () => import("@/views/BottomContainBoxplot"),
            meta: {
              title: "箱线图",
              type: '基础分析'
            },
          },
          {
            path: "BottomContainPolar",
            name: "BottomContainPolar",
            component: () => import("@/views/BottomContainPolar"),
            meta: {
              title: "极坐标图",
              type: '基础分析'
            },
          },
          {
            path: "BottomContainFenxi",
            name: "BottomContainFenxi",
            component: () => import("@/views/BottomContainFenxi"),
            meta: {
              title: "分析",
              type: '基础分析'
            },
          },
          {
            path: "BottomContainForm",
            name: "BottomContainForm",
            component: () => import("@/views/BottomContainForm"),
            meta: {
              title: "表单",
              type: '基础分析'
            },
          },
          {
            path: "BottomContainCollect",
            name: "BottomContainCollect",
            component: () => import("@/views/BottomContainCollect"),
            meta: {
              title: "收藏",
              type: '基础分析'
            },
          },
          {
            path: "PieLittle",
            name: "PieLittle",
            component: () => import("@/views/Pie"),
          },
        ],
      },
      ...basicAnalysis,
      ...highAll,
      ...biologicalInformationProcess,
      ...Tumorbioinformatics,
      ...biologicalInformationAnalysis,
      ...transcriptomeAnalysis,
      ...ProteomeAnalysis,
      ...Metabolome,
      {
        path: "other",
        name: "other",
        component: () => import("@/views/biologicalInformationAnalysis/other/index"),
        meta: {
          type: "待定"
        },
      },
      {
        path: "secondaryClassification",
        name: "secondaryClassification",
        component: () => import("@/views/highmenu/secondaryClassification"),
        meta: {
          title: "二级分类",
        },
      },
      // // 个人中心
      // {
      //   path: "PersonalCenter",
      //   name: "PersonalCenter",
      //   component: () => import("@/views/PersonalCenter/PersonalCenter"),
      //   meta: {
      //     title: "个人中心",
      //   },
      // },

      // 定制分析
      {
        path: "MySelfAnalysis",
        name: "MySelfAnalysis",
        component: () => import("@/views/MySelfAnalysis/MySelfAnalysis"),
        meta: {
          title: "定制分析",
          type: "定制分析"
        },
      },

      // 项目记录
      {
        path: "ProjectOverview",
        name: "ProjectOverview",
        component: () => import("@/views/ProjectOverview/ProjectOverview"),
        meta: {
          title: "项目记录",
          type: "项目记录"
        },
      },
      {
        path: "collectCharts",
        name: "collectCharts",
        component: () => import("@/views/collectChart/collectCharts"),
        meta: {
          title: "我的收藏",
          type: "我的收藏"
        },
      },
      // 论坛
      // {
      //   path: "Talking2",
      //   name: "Talking2",
      //   component: () => import("@/views/Talking/Talking2"),
      //   meta: {
      //     title: "论坛",
      //     type: "论坛"
      //   },
      // },

      // 多个横向柱状图
      {
        path: "MoreZhuDetail",
        name: "MoreZhuDetail",
        component: () => import("@/views/zhuzhuangtu/MoreZhuDetail"),
      },
      // 堆叠条形图
      {
        path: "DuiDieBarDetail",
        name: "DuiDieBarDetail",
        component: () => import("@/views/zhuzhuangtu/DuiDieBarDetail"),
      },
      {
        path: "TruncatedStackedBarChart",
        name: "TruncatedStackedBarChart",
        component: () => import("@/views/zhuzhuangtu/TruncatedStackedBarChart"),
      },

      // 富集分析柱状图
      {
        path: "FuJiFenXiBarDetail",
        name: "FuJiFenXiBarDetail",
        component: () => import("@/views/zhuzhuangtu/FuJiFenXiBarDetail"),
      },
      // 各种基础绘图
      // 各种点图
      {
        path: "DianTu",
        name: "DianTu",
        component: () => import("@/views/diantu/DianTu"),
      },
      // 模块点图
      {
        path: "MoKuaiDianTuDetail",
        name: "MoKuaiDianTuDetail",
        component: () => import("@/views/diantu/MoKuaiDianTuDetail"),
      },
      // 折线图
      {
        path: "ZheXianDetail",
        name: "ZheXianDetail",
        component: () => import("@/views/diantu/ZheXianDetail"),
      },
      // 关系点图
      {
        path: "GuanXiDianTuDetail",
        name: "GuanXiDianTuDetail",
        component: () => import("@/views/diantu/GuanXiDianTuDetail"),
      },
      // 热图
      {
        path: "ReTuDetail",
        name: "ReTuDetail",
        component: () => import("@/views/diantu/ReTuDetail"),
      },
      // 知识谱图
      {
        path: "ZhiShiPuDetail",
        name: "ZhiShiPuDetail",
        component: () => import("@/views/diantu/ZhiShiPuDetail"),
      },
      // 节点关系图
      {
        path: "JieDianGuanXiDetail",
        name: "JieDianGuanXiDetail",
        component: () => import("@/views/diantu/JieDianGuanXiDetail"),
      },
      // 关系图2
      {
        path: "GuanXiTu2Detail",
        name: "GuanXiTu2Detail",
        component: () => import("@/views/diantu/GuanXiTu2Detail"),
      },
      // 气泡图
      {
        path: "BubbleChartDetail",
        name: "BubbleChartDetail",
        component: () => import("@/views/bubbleCharts/BubbleChartDetail"),
      },
      // 散点折线图
      {
        path: "SanDianZheDetail",
        name: "SanDianZheDetail",
        component: () => import("@/views/diantu/SanDianZheDetail"),
      },
      //基础散点图
      {
        path: "ScatterPlotDetail",
        name: "ScatterPlotDetail",
        component: () => import("@/views/pointCharts/ScatterPlotDetail"),
      },
      //分组棒棒糖图
      {
        path: "StackedGroupedLollipopsDetail",
        name: "StackedGroupedLollipopsDetail",
        component: () => import("@/views/pointCharts/StackedGroupedLollipopsDetail"),
      },
      {
        path: "GroupedLollipopDiagramsDetail",
        name: "GroupedLollipopDiagramsDetail",
        component: () => import("@/views/pointCharts/GroupedLollipopDiagramsDetail"),
      },
    ],
  },
  ...WGCNA,
  // 组学流程 -> 转录组分析 -> 常规转录组 (Li)
  {
    path: "/Transcriptome",
    name: "Transcriptome",
    component: () => import("@/views/biologicalInformationProcess/transcriptomeAnalysis/ConventionalTranscriptome/Transcriptome.vue"),
    meta: {
        title: "常规转录组",

    },
  },
  // 招聘
  {
    path: "/jobWorker",
    name: "jobWorker",
    component: () => import("@/views/job/jobWorker"),
    meta: {
      title: "个人中心",
    },
  },
  // 个人中心
  {
    path: "/PersonalCenter",
    name: "PersonalCenter",
    component: () => import("@/views/PersonalCenter/PersonalCenter"),
    meta: {
      title: "个人中心",
    },
  },
  // 404
  {
    path: "/Error",
    name: "Error",
    component: () => import("@/views/Error404/Error"),
    meta: {
      title: "错误界面",
    },
  },
  // 在线客服
  {
    path: "/OnlineService",
    name: "OnlineService",
    component: () => import("@/views/OnlineService/OnlineService"),
    meta: {
      title: "在线客服",
    },
  },
  // 公众号
  {
    path: "/OfficialAccountLists",
    name: "OfficialAccountLists",
    component: () => import("@/views/officialAccount/OfficialAccountLists"),
    meta: {
      title: "公众号",
    },
  },
  // 实验机制/研究路线图绘制
  {
    path: "/ResearchChart",
    name: "ResearchChart",
    component: () => import("@/views/ResearchChart/ResearchChart"),
    meta: {
      title: "实验机制/研究路线图绘制",
    },
  },
  // 关于我们
  {
    path: "/AboutOur",
    name: "AboutOur",
    component: () => import("@/views/AboutOur/AboutOur"),
    meta: {
      title: "关于我们",
    },
  },
  // 常见问题
  {
    path: "/Question",
    name: "Question",
    component: () => import("@/views/UsuallyAsked/Question"),
    meta: {
      title: "常见问题",
    },
  },
  // 引用送现金
  {
    path: "/UseGiveMoney",
    name: "UseGiveMoney",
    component: () => import("@/views/UseGiveMoney/UseGiveMoney"),
    meta: {
      title: "引用送现金",
    },
  },
  // 消息通知
  {
    path: "/News",
    name: "News",
    component: () => import("@/views/News/News"),
    meta: {
      title: "消息通知",
    },
  },
  // GPT
  {
    path: "/GPTS",
    name: "GPTS",
    component: () => import("@/views/GPT/GPTS"),
    meta: {
      title: "GPT合集",
    },
    // children: [
    //   // AI充值界面
    //   {
    //     path: "/RechargeMoney",
    //     name: "RechargeMoney",
    //     component: () => import("@/views/GPT/RechargeMoney"),
    //     meta: {
    //       title: "充值界面",
    //     },
    //   }
    // ]
  },
  // 这是视频解说界面
  {
    path: "/Videos",
    name: "Videos",
    component: () => import("@/views/Video/Videos"),
    meta: {
      title: "视频解说",
    },
  },
  // 搜索界面
  {
    path: "/SearchPage",
    name: "SearchPage",
    component: () => import("@/views/SearchPage/SearchPage"),
    meta: {
      title: "搜索界面",
    },
  },
   // 单细胞路由
   {
    path: "/Cell",
    name: "Cell",
    component: () => import("@/views/biologicalInformationProcess/singleCellAnalysis/Cell.vue"),
    meta: {
        title: "细胞",
        type: '单细胞分析'
    },
},
]
const router = new VueRouter({ routes })
// let pid = sessionStorage.getItem("ThePid");
// console.log(router.currentRoute.fullPath);

router.beforeEach((to, from, next) => {
  // console.log(to, from, next);

  // var formData = new FormData();
  // formData.append("pid", pid);
  // axios
  //   .post("https://cnsknowall.com/api/projects", formData)
  //   .then((response) => {
  //     ac_name = response.data[0].c2name
  //     console.log("数据已成功发送到后端：", response, ac_name);
  //   })
  //   .catch((error) => {
  //     console.error("发送数据到后端时出错：", error);
  //   });
  if (to.meta.type === '高级分析') {
    sessionStorage.setItem("default-active", '1-2');
  } else if (to.meta.type === '基础分析') {
    sessionStorage.setItem("default-active", '1-1');
  } else if (to.meta.type === '项目记录') {
    sessionStorage.setItem("default-active", '5');
  } else if (to.meta.type === '微生物生信') {
    sessionStorage.setItem("default-active", '2-1');
  } else if (to.meta.type === '肿瘤生信') {
    sessionStorage.setItem("default-active", '2-2');
  } else if (to.meta.type === '待定') {
    sessionStorage.setItem("default-active", '2-3');
  } else if (to.meta.type === '单细胞分析') {
    sessionStorage.setItem("default-active", '3-1');
  } else if (to.meta.type === '转录组分析') {
    sessionStorage.setItem("default-active", '3-2');
  } else if (to.meta.type === '蛋白组分析') {
    sessionStorage.setItem("default-active", '3-3');
  } else if (to.meta.type === '代谢组') {
    sessionStorage.setItem("default-active", '3-4');
  } else if (to.meta.type === '代谢组') {
    sessionStorage.setItem("default-active", '4');
  } 
  // else if (to.meta.type === '我的收藏') {
  //   sessionStorage.setItem("default-active", '6');
  // }
  next();
});
export default router
