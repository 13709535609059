<template>
  <div
    style="
      display: flex;
      align-items: center;
      margin: 0px 0;
      height: 40px;
      padding-left: 17px;
      font-size: 13px;
    "
  >
    <!-- 显示提示词 -->

    <!--  -->
    <label>{{ label }}：</label>
    <el-input
      placeholder="请输入内容"
      v-model="input"
      :disabled="true"
      style="width: 38%; height: 40px"
    >
    </el-input>
    <span
      class="example"
      @click="openModal"
      @mouseover="showDiv2"
      @mouseout="hideDiv2"
      v-show="status == true"
      >选择文件</span
    >
    <span
      class="example"
      v-show="status == false"
      style="background: #999; cursor: no-drop"
      >选择文件</span
    >
    <template v-if="!isVeen">
      <Example v-if="isExample" :xlsxData="lists"></Example>
    </template>
    <template v-else>
      <ExampleVeen v-if="isExample" :xlsxData="lists"></ExampleVeen>
    </template>
    

    <!-- 模态框 -->
    <div v-show="modalOpen" class="modal">
      <div
        class="modal-content"
        ref="modalContent"
        :style="{ top: modalY + 'px', left: modalX + 'px' }"
        @mousedown="startDrag"
      >
        <span
          class="close"
          @click="closeModal"
          style="position: absolute; right: 9px; top: -3px; font-size: 35px"
          >&times;</span
        >
        <h3 class="top" style="padding-bottom: 17px">
          上传文件
          <span style="font-size: 13px; color: red; margin-left: 4px"
            >(注意！此方式无需点击表格下方"上传"按钮)</span
          >
        </h3>
        <el-upload
          ref="upload"
          :action="baseUrl + '/upload/' + $route.query.pid"
          :data="{...paramsAgain,nocover: 1}"
          :drag="true"
          :auto-upload="true"
          class="upload-demo"
          :on-success="handleSuccess"
          :before-upload="getbefore"
          :multiple="true"
          :with-credentials="true"
          :limit="1"
          :on-change="getStatus"
        >
          <!--  :on-change="getStatus" -->
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或<em>点击上传</em> <i> (为自动上传)</i>
          </div>
        </el-upload>
        <!-- <el-button type="primary" plain style="float: right" @click="toUpload"
          >上传</el-button
        > -->
      </div>
    </div>
    <div
      v-show="showSecondDiv"
      class="bottomTips"
      style="
        position: absolute;
        top: 98px;
        z-index: 10000;
        background: black;
        color: #fff;
        padding: 5px;
        margin-right: 32px;
        border-radius: 6px;
      "
    >
      <div class="tips">
        强烈推荐优先将本地数据直接复制粘贴（Ctrl
        C+V）到下方表格文字框进行上传，文件上传容易因未知格式报错！！！
      </div>
    </div>
  </div>
</template>

<script>
import Example from "./Example.vue";
import ExampleVeen from "../ExampleVeen.vue";
import { Loading, Message } from "element-ui";
export default {
  components: {
    Example,
    ExampleVeen
  },
  props: {
    interfaces: {
      type: String,
    },
    lists: {
      type: Array,
    },
    params: {
      type: Object,
      default: () => ({
        projectname: "",
        nocover: 1,
      }),
    },
    paramNameList: {
      type: String,
      default: null,
    },
    isExample: {
      type: Boolean,
      default: true,
    },
    istxt: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "文件名",
    },
    status: {
      type: Boolean,
      default: true,
    },
    isVeen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Num: "",
      paramName: "",
      fileList: [],
      paramsAgain: null,
      butIsShow: false,
      showSecondDiv: false,
      // 模态框的数据
      modalOpen: false,
      isDragging: false,
      mouseX: 0,
      mouseY: 0,
      modalX: 0,
      modalY: 0,
      offsetX: 0,
      offsetY: 0,
      baseUrl: window.g.baseUrl,
      input: "",
      listsData: [],
      loadinginstace: null,
      t: null,
    };
  },
  mounted() {
    if (this.paramNameList) {
      console.log(666, this.input);
      this.input = this.paramNameList;
    }
    this.paramsAgain = this.params;
    
    // console.log(this.params);
    if (this.paramsAgain.setGraph) {
      this.paramsAgain.files = this.fileList;
      this.paramsAgain.setGraph = JSON.stringify(this.paramsAgain.setGraph);
    }
  },
  watch: {
    params: function (newVal, oldVal) {
      this.paramsAgain = this.params;
      // console.log(this.params);
      if (this.paramsAgain.setGraph) {
        this.paramsAgain.setGraph = JSON.stringify(this.paramsAgain.setGraph);
      }
    },
    // params: {
    //   handler: function () {
    //     this.paramsAgain = this.params;
    //     console.log(this.params);
    //     if (this.paramsAgain.setGraph) {
    //       this.paramsAgain.setGraph = JSON.stringify(this.paramsAgain.setGraph);
    //     }
    //   },
    //   deep: true,
    // },
    paramNameList: {
      handler: function () {
        if (this.paramNameList) {
          console.log(666, this.input);
          this.input = this.paramNameList;
        }
      },
    },
  },
  methods: {
    getStatus() {
      this.butIsShow = true;
      sessionStorage.setItem("Symble", 1);
    },
    toUpload(num) {
      console.log(num);
      this.Num = num;
      if (this.Num) {
        let t;
        let xhr = null;
        let time = 60;
        this.loadinginstace = Loading.service({
          lock: true,
          text: "正在处理，请稍后。。。",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.5)",
        });
        t = setInterval(() => {
          time--;
          if (time > 0) {
            switch (true) {
              case time <= 30 && 0 < time:
                this.loadinginstace.text =
                  "您提交的数据量较大，请耐心等待。。。";
                break;
              default:
                break;
            }
          } else {
            clearInterval(t);
            // if (xhr) {
            //     xhr.abort();
            // }
            this.loadinginstace.close();

            this.$confirm("正在处理，可在我的项目里获取结果", "提示", {
              confirmButtonText: "前往我的项目",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push({
                  name: "ProjectOverview",
                });
              })
              .catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '已取消删除'
                // });
              });
          }
        }, 1000);
        if (this.Num.filesData) {
          var params2 = {
            file: this.fileList,
            ...this.paramsAgain,
            paramName: this.input,
            Num: this.Num.Num,
            files: this.Num.filesData,
          };
        } else {
          var params2 = {
            file: this.fileList,
            ...this.paramsAgain,
            paramName: this.input,
            Num: this.Num.Num,
          };
        }

        // params2.setGraph = this.paramsAgain,
        this.$post("/upload/" + this.$route.query.pid, {...params2,nocover: 1})
          .then((response) => {
            clearInterval(t);
            this.loadinginstace.close();
            // console.log(response); //后台返回的数据
            if (
              response.data.error == "User Logged in elsewhere" ||
              response.data.error == "User Not Login"
            ) {
              this.$message({
                showClose: false,
                message: response.data.error,
                type: "error",
              });
              this.$router.push("/");
            } else {
              if (response.data.error.length > 0) {
                this.$message({
                  showClose: false,
                  message: response.data.error,
                  type: "error",
                });
              } else if (response.data.error.msg) {
                this.$message({
                  showClose: false,
                  message: response.data.error.msg,
                  type: "error",
                });
              } else {
                this.listsData = response.data;
                this.$emit("handleSuccess", this.listsData);
              }
            }
          })
          .catch((error) => {
            clearInterval(t);
            if (xhr) {
              xhr.abort(); // Abort the request if it's still ongoing
            }
            this.loadinginstace.close();
            this.$message({
              showClose: true,
              message: "数据错误,请检查数据格式",
              type: "error",
            });
          });
      }
    },
    showDiv2() {
      this.showSecondDiv = true;

      // console.log(666, this.showSecondDiv);
    },
    hideDiv2() {
      this.showSecondDiv = false;
    },
    // 文件拖拽上传
    handleSuccess(response, file, fileList) {
      this.fileList = fileList[0].raw;
      console.log(this.fileList);
      // 处理上传成功后的操作
      // console.log("上传成功", response, file);
      // console.log("我是文件上传");

      clearInterval(this.t);

      this.loadinginstace.close();
      this.input = file.name;
      if (
        response.error == "User Logged in elsewhere" ||
        response.error == "User Not Login"
      ) {
        this.$message({
          showClose: true,
          message: response.error,
          type: "error",
        });
        this.$router.push("/");
      } else {
        if (response.error.length > 0) {
          this.$message({
            showClose: true,
            message: response.error,
            type: "error",
          });
        } else if (response.error.msg) {
          this.$message({
            showClose: true,
            message: response.error.msg,
            type: "error",
          });
        } else {
          this.listsData = response;
          this.$emit("handleSuccess", this.listsData);
        }
      }
    },

    getbefore(file) {
      console.log(file);
      this.$emit("beforeUpload", file);
      let istxt = this.$props.istxt;
      if (istxt) {
        const type = file.type === "text/plain";
        if (!type) {
          this.$message.error("上传的文件只能是 txt 格式!");
        } else {
          this.modalOpen = false;
          this.loadinginstace = Loading.service({
            lock: true,
            text: "正在处理，请稍后。。。",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.5)",
          });
        }
        return type;
      } else {
        let type =
          file.type === "text/csv" ||
          file.type === "text/plain" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          file.type === "application/vnd.ms-excel" ||
          file.type === "";

        const fileName = file.name;

        // 定义允许的压缩包文件扩展名列表
        const allowedExtensions = [
          ".zip",
          ".cab",
          ".jar",
          ".tar",
          ".gz",
          ".z",
          ".rar",
          ".7z",
        ];

        // 提取文件扩展名
        const fileExtension = fileName
          .substring(fileName.lastIndexOf(".") + 1)
          .toLowerCase();

        if (!type) {
          if (allowedExtensions.includes("." + fileExtension)) {
            //是否为压缩包
            type = true;
          } else {
            this.$message.error("上传的文件格式有误!");
          }
          return type;
        } else {
          this.modalOpen = false;
          this.t;
          let time = 60;
          this.loadinginstace = Loading.service({
            lock: true,
            text: "正在处理，请稍后。。。",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.5)",
          });
          this.t = setInterval(() => {
            time--;
            if (time > 0) {
              switch (true) {
                case time <= 30 && 0 < time:
                  this.loadinginstace.text =
                    "您提交的数据量较大，请耐心等待。。。";
                  break;
                default:
                  break;
              }
            } else {
              clearInterval(this.t);
              // if (xhr) {
              //     xhr.abort();
              // }
              this.loadinginstace.close();

              this.$confirm("正在处理，可在我的项目里获取结果", "提示", {
                confirmButtonText: "前往我的项目",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.$router.push({
                    name: "ProjectOverview",
                  });
                })
                .catch(() => {
                  // this.$message({
                  //     type: 'info',
                  //     message: '已取消删除'
                  // });
                });
            }
          }, 1000);
        }
        return type;
      }
    },
    // 模态框的方法
    openModal() {
      this.modalOpen = true;
      this.$nextTick(() => {
        this.centerModal();
      });
    },
    closeModal() {
      if (this.$refs.upload.uploadFiles.length > 0) {
        this.$confirm("关闭后将会中断上传，确定关闭吗", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            // 中止文件上传
            this.$refs.upload.abort();
            this.modalOpen = false;
          })
          .catch(() => {});
      } else {
        this.modalOpen = false;
      }
    },

    startDrag(event) {
      this.isDragging = true;
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
      const modalContent = this.$refs.modalContent;
      const modalRect = modalContent.getBoundingClientRect();
      this.offsetX = this.mouseX - modalRect.left;
      this.offsetY = this.mouseY - modalRect.top;
      document.addEventListener("mousemove", this.dragModal);
      document.addEventListener("mouseup", this.stopDrag);
    },
    dragModal(event) {
      if (!this.isDragging) return;
      const newX = event.clientX - this.offsetX;
      const newY = event.clientY - this.offsetY;
      this.modalX = newX;
      this.modalY = newY;
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.dragModal);
      document.removeEventListener("mouseup", this.stopDrag);
    },
    centerModal() {
      const modalContent = this.$refs.modalContent;
      const modalRect = modalContent.getBoundingClientRect();
      const windowWidth =
        window.innerWidth || document.documentElement.clientWidth;
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const modalWidth = modalRect.width;
      const modalHeight = modalRect.height;
      this.modalX = (windowWidth - modalWidth) / 2;
      this.modalY = (windowHeight - modalHeight) / 2;
      // console.log(666);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload {
  // 修改处的css，6.13
  .example {
    display: inline-block;
    padding: 5px 7px;
    text-align: center;
    white-space: nowrap;
    font-size: 14px;
    border: none;
    vertical-align: top;
    height: 35px;
    line-height: 25px;
    color: #fff;
    background-color: #0294a7;
    border-color: #026c7a;
    -webkit-box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin-left: 10px;
    border-radius: 5px;
    cursor: pointer;
  }

  .example1 {
    color: #006cbf;
    font-size: 14px;
    margin-left: 10px;
  }

  // 模态框样式
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    cursor: move;
    user-select: none;
    position: absolute;
  }

  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  // fuzi复制样式
  .copyAll {
    button {
      margin-top: 10px;
      cursor: pointer;
    }

    .copy {
      .fixed-size {
        resize: none;
        width: 300px;
        /* 设置宽度 */
        height: 200px;
        /* 设置高度 */
      }

      table {
        table-layout: fixed;
        /* 使用固定表格布局 */
      }

      th {
        white-space: nowrap;
        /* 不换行 */
        overflow: hidden;
        /* 隐藏溢出文本 */
        text-overflow: ellipsis;
        /* 当文本溢出时显示省略号 */
      }
    }
  }

  .dropzone {
    border: 2px dashed #ccc;
    padding: 20px;
    cursor: pointer;

    &:hover {
      border-color: #aaa;
    }

    .upFile {
      margin-top: 15px;
      display: flex;
      align-items: center;

      .example {
        display: inline-block;
        padding: 5px 8px;
        font-size: 14px;
        border: none;
        vertical-align: top;
        height: 32px;
        color: #fff;
        background-color: #0294a7;
        border-color: #026c7a;
        -webkit-box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
      }

      .input22 {
        width: 80%;
        border: none;
        border-radius: 5px;
        padding: 10px;
        width: 100%;
        font-size: 16px;
        color: #666666;
        background-color: #f5f5f5;
        box-shadow: inset 0 0 5px #cccccc;
      }
    }

    .but {
      width: 100px;
      margin-top: 15px;
    }
  }
}
</style>