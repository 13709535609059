const basicAnalysis = [
    {
        path: "Pie",
        name: "Pie",
        component: () => import("@/views/Pie"),
        meta: {
            title: "饼图",
        },
    },
    {
        path: "Zhu",
        name: "Zhu",
        component: () => import("@/views/Zhu"),
        meta: {
            title: "柱状图",
        },
    },
    // 堆积柱状图

    // 进化树
    {
        path: "cladogram",
        name: "cladogram",
        component: () => import("@/views/TreeCharts/cladogram"),
        meta: {
            title: "进化树",
        },
    },
    // 双x轴连线图
    {
        path: "BiaxialConnectionDiagram",
        name: "BiaxialConnectionDiagram",
        component: () => import("@/views/pointCharts/BiaxialConnectionDiagram"),
        meta: {
            title: "双x轴连线图",
        },
    },
    // 蜂群图
    {
        path: "honeybeeColonies",
        name: "honeybeeColonies",
        component: () => import("@/views/pointCharts/honeybeeColonies"),
        meta: {
            title: "蜂群图",
        },
    },
    // 分组散点图
    {
        path: "Groupedscatterplots",
        name: "Groupedscatterplots",
        component: () => import("@/views/pointCharts/Groupedscatterplots"),
        meta: {
            title: "分组散点图",
        },
    },

    {
        path: "ChinaMap",
        name: "ChinaMap",
        component: () => import("@/views/map/ChinaMap"),
        meta: {
            title: "中国地图",
        },
    },
    {
        path: "WorldMap",
        name: "WorldMap",
        component: () => import("@/views/map/WorldMap"),
        meta: {
            title: "世界地图",
        },
    },
    {
        path: "USAMap",
        name: "USAMap",
        component: () => import("@/views/map/USAMap"),
        meta: {
            title: "美国地图",
        },
    },
    {
        path: "Sangji",
        name: "Sangji",
        component: () => import("@/views/Sangji"),
        meta: {
            title: "桑基图",
        },
    },

    // 各种饼图
    {
        path: "Pie1Detail",
        name: "Pie1Detail",
        component: () => import("@/views/Pie1Detail"), meta: {
            type: '基础分析'
        }
    },
    {
        path: "TwoBingDetail",
        name: "TwoBingDetail",
        component: () => import("@/views/TwoBingDetail"), meta: {
            type: '基础分析'
        }
    },
    {
        path: "Pie3DDetail",
        name: "Pie3DDetail",
        component: () => import("@/views/Pie3DDetail"), meta: {
            type: '基础分析'
        }
    },
    {
        path: "FanPie",
        name: "FanPie",
        component: () => import("@/views/PieChart/FanPie"),
        meta: {
            type: '基础分析'
        }
    },
    // 矩阵饼图
    {
        path: "MatrixPie",
        name: "MatrixPie",
        component: () => import("@/views/PieChart/MatrixPie"),
        meta: {
            type: '基础分析'
        }
    },
    // 日历饼图
    {
        path: "CalendarPie",
        name: "CalendarPie",
        component: () => import("@/views/PieChart/CalendarPie"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "roseDiagram",
        name: "roseDiagram",
        component: () => import("@/views/PieChart/roseDiagram"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "RecombinationZhuPie",
        name: "RecombinationZhuPie",
        component: () => import("@/views/pie/RecombinationZhuPie"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "TwoSetsOfSubgraphs",
        name: "TwoSetsOfSubgraphs",
        component: () => import("@/views/pie/TwoSetsOfSubgraphs"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "RecombinationLoopPie",
        name: "RecombinationLoopPie",
        component: () => import("@/views/pie/RecombinationLoopPie"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "MeiguiDetail",
        name: "MeiguiDetail",
        component: () => import("@/views/MeiguiDetail"), meta: {
            type: '基础分析'
        }
    },
    {
        path: "MultiLoopPieDetail",
        name: "MultiLoopPieDetail",
        component: () => import("@/views/MultiLoopPieDetail"), meta: {
            type: '基础分析'
        }
    },

    {
        path: "XuRiTu",
        name: "XuRiTu",
        component: () => import("@/views/XuRiTuDetail"), meta: {
            type: '基础分析'
        }
    },
    // 三环饼图
    {
        path: "SanHuanPie",
        name: "SanHuanPie",
        component: () => import("@/views/SanHuanPieDetail"), meta: {
            type: '基础分析'
        }
    },
    // 五个饼图
    {
        path: "MorePieDetail",
        name: "MorePieDetail",
        component: () => import("@/views/pie/MorePieDetail"), meta: {
            type: '基础分析'
        }
    },
    {
        path: "dynamicPie",
        name: "dynamicPie",
        component: () => import("@/views/pie/dynamicPie"), meta: {
            type: '基础分析'
        }
    },
    //和弦图
    {
        path: "ChordalGraphDetail",
        name: "ChordalGraphDetail",
        component: () => import("@/views/pie/ChordalGraphDetail"),
        meta: {
            type: '基础分析'
        }
    },
    //矩阵饼图
    {
        path: "PieMatrixDetail",
        name: "PieMatrixDetail",
        component: () => import("@/views/pie/PieMatrixDetail"),
        meta: {
            type: '基础分析'
        }
    },
    //矩阵饼图
    {
        path: "MatrixThePieDetail",
        name: "MatrixThePieDetail",
        component: () => import("@/views/pie/MatrixThePieDetail"),
        meta: {
            type: '基础分析'
        }
    },
    //Circos圈图
    {
        path: "CircosCircleDetail",
        name: "CircosCircleDetail",
        component: () => import("@/views/pie/CircosCircleDetail"),
        meta: {
            type: '基础分析'
        }
    },
    //分组弦图
    {
        path: "GroupLineChord",
        name: "GroupLineChord",
        component: () => import("@/views/pie/GroupLineChord"),
        meta: {
            type: '基础分析'
        }
    },
    // 饼图柱状图集
    {
        path: "PieAndBarcharDetail",
        name: "PieAndBarcharDetail",
        component: () => import("@/views/pie/PieAndBarcharDetail"), meta: {
            type: '基础分析'
        }
    },
    {
        path: "DynamicRose",
        name: "DynamicRose",
        component: () => import("@/views/PieChart/DynamicRose"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "HalfCircle",
        name: "HalfCircle",
        component: () => import("@/views/PieChart/HalfCircle"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "FanRose",
        name: "FanRose",
        component: () => import("@/views/PieChart/FanRose"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "HalfBar",
        name: "HalfBar",
        component: () => import("@/views/PieChart/HalfBar.vue"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "matrixPies",
        name: "matrixPies",
        component: () => import("@/views/PieChart/matrixPies.vue"),
        meta: {
            type: '基础分析'
        }
    },

    // 各种条形图
    {
        path: "ZhuDetail",
        name: "ZhuDetail",
        component: () => import("@/views/ZhuDetail"),
    },
    //富集分组柱状图
    {
        path: "ClassifyBar",
        name: "ClassifyBar",
        component: () => import("@/views/AdvancedAnalysis/enrichmentAnalysis/ClassifyBar"),
    },
    //蝶形箱线柱状图
    {
        path: "ButterflyBoxLinePost",
        name: "ButterflyBoxLinePost",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/ButterflyBoxLinePost"),
    },
    //差异柱状图
    {
        path: "DifferenceBar",
        name: "DifferenceBar",
        component: () => import("@/views/AdvancedAnalysis/DifferenceTest/DifferenceBar"),
    },
    {
        path: "SinglePieDetail",
        name: "SinglePieDetail",
        component: () => import("@/views/SinglePieDetail"),
    },
    {
        path: "SimpleBarDetail",
        name: "SimpleBarDetail",
        component: () => import("@/views/zhuzhuangtu/SimpleBarDetail"),
    },

    {
        path: "Sangji1Detail",
        name: "Sangji1Detail",
        component: () => import("@/views/Sangji1Detail"),
    },
    {
        path: "SangjiDetail2",
        name: "SangjiDetail2",
        component: () => import("@/views/SangjiDetail2"),
    },
    {
        path: "SangjiDetail3",
        name: "SangjiDetail3",
        component: () => import("@/views/SangjiDetail3"),
    },
    {
        path: "SangjiDetail4",
        name: "SangjiDetail4",
        component: () => import("@/views/SangjiDetail4"),
    },
    {
        path: "HotPies",
        name: "HotPies",
        component: () => import("@/views/Hotpies"),
    },
    {
        path: "PieDonutDetail",
        name: "PieDonutDetail",
        component: () => import("@/views/PieDonutDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "PieDonut3dDetail",
        name: "PieDonut3dDetail",
        component: () => import("@/views/PieDonut3dDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "symmetricBar",
        name: "symmetricBar",
        component: () => import("@/views/zhuzhuangtu/symmetricBar"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "percentageBar",
        name: "percentageBar",
        component: () => import("@/views/zhuzhuangtu/percentageBar"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "TwoSides",
        name: "TwoSides",
        component: () => import("@/views/zhuzhuangtu/TwoSides"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "BasicBar",
        name: "BasicBar",
        component: () => import("@/views/zhuzhuangtu/BasicBar"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "StackBar",
        name: "StackBar",
        component: () => import("@/views/stackBar/StackBar"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "TruncationBar",
        name: "TruncationBar",
        component: () => import("@/views/barCharts/TruncationBar"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "ringGroup",
        name: "ringGroup",
        component: () => import("@/views/zhuzhuangtu/ringGroup"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "varwideBar",
        name: "varwideBar",
        component: () => import("@/views/barCharts/varwideBar"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "bar3D",
        name: "bar3D",
        component: () => import("@/views/barCharts/bar3D"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "nestedBar",
        name: "nestedBar",
        component: () => import("@/views/barCharts/nestedBar"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "lineBar",
        name: "lineBar",
        component: () => import("@/views/barCharts/lineBar"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "radarBar",
        name: "radarBar",
        component: () => import("@/views/zhuzhuangtu/radarBar"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "stackAndPercentBar",
        name: "stackAndPercentBar",
        component: () => import("@/views/stackBar/stackAndPercentBar"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "manyBars",
        name: "manyBars",
        component: () => import("@/views/barCharts/manyBars"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "BardYnamicDetail",
        name: "BardYnamicDetail",
        component: () => import("@/views/barCharts/BardYnamicDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "OrthogonDetail",
        name: "OrthogonDetail",
        component: () => import("@/views/barCharts/OrthogonDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "errorBar",
        name: "errorBar",
        component: () => import("@/views/barCharts/errorBar"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "basicWaterfall",
        name: "basicWaterfall",
        component: () => import("@/views/barCharts/basicWaterfall"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "compareFunnel",
        name: "compareFunnel",
        component: () => import("@/views/barCharts/compareFunnel"),
        meta: {
            type: '基础分析',
            title: '对比漏斗图',
        }
    },

    //
    {
        path: "MapBubbleDetail",
        name: "MapBubbleDetail",
        component: () => import("@/views/map/MapBubbleDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "MapDonutDetail",
        name: "MapDonutDetail",
        component: () => import("@/views/map/MapDonutDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "MapClassDetail",
        name: "MapClassDetail",
        component: () => import("@/views/map/MapClassDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "MapWorldDetail",
        name: "MapWorldDetail",
        component: () => import("@/views/map/MapWorldDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "MapPlusBarPieDetail",
        name: "MapPlusBarPieDetail",
        component: () => import("@/views/map/MapPlusBarPieDetail"),
        meta: {
            type: '基础分析'
        }
    },

    {
        path: "MapSortBubbleDetail",
        name: "MapSortBubbleDetail",
        component: () => import("@/views/map/MapSortBubbleDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "MapShowDetail",
        name: "MapShowDetail",
        component: () => import("@/views/map/MapShowDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "MapPieUSADetail",
        name: "MapPieUSADetail",
        component: () => import("@/views/map/MapPieUSADetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "MapPieDetail",
        name: "MapPieDetail",
        component: () => import("@/views/map/MapPieDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "MapGradeBubbleDetail",
        name: "MapGradeBubbleDetail",
        component: () => import("@/views/map/MapGradeBubbleDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "MapDetail",
        name: "MapDetail",
        component: () => import("@/views/map/MapDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "MapBarDetail",
        name: "MapBarDetail",
        component: () => import("@/views/map/MapBarDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "Map3dDetail",
        name: "Map3dDetail",
        component: () => import("@/views/map/Map3dDetail"),
        meta: {
            type: '基础分析'
        }
    },

    //美国区域地图
    {
        path: "USAGradedDetail",
        name: "USAGradedDetail",
        component: () => import("@/views/map/USAGradedDetail"),
        meta: {
            type: '基础分析'
        }
    },
    //省份环形图
    {
        path: "GuizhouMap",
        name: "GuizhouMap",
        component: () => import("@/views/map/ProvinceMap/GuizhouMap"),
        meta: {
            type: '基础分析'
        }
    },
    //省份热度地图
    {
        path: "ProvinceThermalMap",
        name: "ProvinceThermalMap",
        component: () => import("@/views/map/ProvinceMap/ProvinceThermalMap"),
        meta: {
            type: '基础分析'
        }
    },
    //中国地级市图
    {
        path: "ChinaCitiesMap",
        name: "ChinaCitiesMap",
        component: () => import("@/views/map/ProvinceMap/ChinaCitiesMap"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "BiaxialBarDetail",
        name: "BiaxialBarDetail",
        component: () => import("@/views/BiaxialBarDetail"),
        meta: {
            type: '基础分析'
        }
    },
    // P值三线表图
    {
        path: "PThreeWireChart",
        name: "PThreeWireChart",
        component: () => import("@/views/FormsAndFormats/PThreeWireChart"),
        meta: {
            type: '基础分析'
        }
    },
    // 三线表图
    {
        path: "ThreeWireChart",
        name: "ThreeWireChart",
        component: () => import("@/views/FormsAndFormats/ThreeWireChart"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "xlsxConvertDetail",
        name: "xlsxConvertDetail",
        component: () => import("@/views/xlsxConvertDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "xlsxMergeDetail",
        name: "xlsxMergeDetail",
        component: () => import("@/views/xlsxMergeDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "xlsxReplaceDetail",
        name: "xlsxReplaceDetail",
        component: () => import("@/views/xlsxReplaceDetail"),
        meta: {
            type: '基础分析'
        }
    },
    // 各种散点图
    {
        path: "Bubble",
        name: "Bubble",
        component: () => import("@/views/bubbleCharts/Bubble"),
        meta: {
            type: '基础分析'
        }
    },
    // 3D气泡图
    {
        path: "Bubble3DDetail",
        name: "Bubble3DDetail",
        component: () => import("@/views/bubbleCharts/Bubble3DDetail"),
        meta: {
            type: '基础分析'
        }
    },
    // 包珠图
    {
        path: "BeadDrawingDetail",
        name: "BeadDrawingDetail",
        component: () => import("@/views/bubbleCharts/BeadDrawingDetail"),
        meta: {
            type: '基础分析'
        }
    },
    // 玫瑰气泡图
    {
        path: "RoseBubbleDiagram",
        name: "RoseBubbleDiagram",
        component: () => import("@/views/bubbleCharts/RoseBubbleDiagram"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "roundStacked",
        name: "roundStacked",
        component: () => import("@/views/bubbleCharts/roundStacked"),
        meta: {
            type: '基础分析'
        }
    },
    //富集分析结果展示气泡图
    {
        path: "EnrichmentBubbleDetail",
        name: "EnrichmentBubbleDetail",
        component: () => import("@/views/bubbleCharts/EnrichmentBubbleDetail"),
        meta: {
            type: '基础分析'
        }
    },
    //气泡图
    {
        path: "BubbleChartsAxis",
        name: "BubbleChartsAxis",
        component: () => import("@/views/bubbleCharts/BubbleChartsAxis"),
        meta: {
            type: '基础分析'
        }
    },
    //动态气泡图
    {
        path: "dynamicBubbles",
        name: "dynamicBubbles",
        component: () => import("@/views/bubbleCharts/dynamicBubbles"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "dynamicBubble",
        name: "dynamicBubble",
        component: () => import("@/views/bubbleCharts/dynamicBubble"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "dumbbell",
        name: "dumbbell",
        component: () => import("@/views/dumbbellDiagramCharts/dumbbell"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "point3D",
        name: "point3D",
        component: () => import("@/views/pointCharts/point3D"),
        meta: {
            type: '基础分析'
        }
    },
    //基因差异表达散点图
    {
        path: "DiffScatterPlotsDetail",
        name: "DiffScatterPlotsDetail",
        component: () => import("@/views/pointCharts/DiffScatterPlotsDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "lollipop",
        name: "lollipop",
        component: () => import("@/views/pointCharts/lollipop"),
        meta: {
            type: '基础分析'
        }
    },
    // 三元图
    {
        path: "TernaryGraph",
        name: "TernaryGraph",
        component: () => import("@/views/pointCharts/TernaryGraph"),
        meta: {
            type: '基础分析'
        }
    },
    // 拟合曲线散点图
    {
        path: "FitTheCurveScatter",
        name: "FitTheCurveScatter",
        component: () => import("@/views/pointCharts/FitTheCurveScatter"),
        meta: {
            type: '基础分析'
        }
    },
    // 分组线性回归
    {
        path: "GroupedLinearRegression",
        name: "GroupedLinearRegression",
        component: () => import("@/views/pointCharts/GroupedLinearRegression"),
        meta: {
            type: '基础分析'
        }
    },

    // 各种雷达图
    {
        path: "BasicRadar",
        name: "BasicRadar",
        component: () => import("@/views/polarCharts/BasicRadar"),
        meta: {
            type: '基础分析'
        }
    },
    //极坐标散点图
    {
        path: "PolarScattersDetail",
        name: "PolarScattersDetail",
        component: () => import("@/views/polarCharts/PolarScattersDetail"),
        meta: {
            type: '基础分析'
        }
    },
    //极坐标面积图
    {
        path: "PolarAreaDetail",
        name: "PolarAreaDetail",
        component: () => import("@/views/polarCharts/PolarAreaDetail"),
        meta: {
            type: '基础分析'
        }
    },
    //网络图
    //权重网络图
    {
        path: "weightsNetwork",
        name: "weightsNetwork",
        component: () => import("@/views/TreeCharts/weightsNetwork.vue"),
        meta: {
            type: '基础分析'
        }
    },
    //基础网络图
    {
        path: "basicNetwork",
        name: "basicNetwork",
        component: () => import("@/views/TreeCharts/basicNetwork.vue"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "groupNetwork",
        name: "groupNetwork",
        component: () => import("@/views/TreeCharts/groupNetwork.vue"),
        meta: {
            type: '基础分析'
        }
    },
    //环形拓扑图
    {
        path: "TopologyDetail",
        name: "TopologyDetail",
        component: () => import("@/views/TreeCharts/TopologyDetail.vue"),
        meta: {
            type: '基础分析'
        }
    },
    //桑吉图
    {
        path: "SangituDetail",
        name: "SangituDetail",
        component: () => import("@/views/TreeCharts/SangituDetail.vue"),
        meta: {
            type: '基础分析'
        }
    },
    //分组桑吉图
    {
        path: "GroupingSanjiDiagram",
        name: "GroupingSanjiDiagram",
        component: () => import("@/views/TreeCharts/GroupingSanjiDiagram.vue"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "SrcBar",
        name: "SrcBar",
        component: () => import("@/views/barCharts/SrcBar"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "CompositeBar",
        name: "CompositeBar",
        component: () => import("@/views/barCharts/CompositeBar"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "PolarColumn",
        name: "PolarColumn",
        component: () => import("@/views/barCharts/PolarColumn"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "HemihedralColumn",
        name: "HemihedralColumn",
        component: () => import("@/views/barCharts/HemihedralColumn"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "fanshapedBar",
        name: "fanshapedBar",
        component: () => import("@/views/barCharts/fanshapedBar"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "PieBar",
        name: "PieBar",
        component: () => import("@/views/zhuChart/PieBar"),
        meta: {
            type: '基础分析'
        }
    },
    {
        // 嵌套柱状图
        path: "NestedColumns",
        name: "NestedColumns",
        component: () => import("@/views/zhuChart/NestedColumns"),
        meta: {
            type: '基础分析'
        }
    },
    {
        // 砖式条形图
        path: "BrickBar",
        name: "BrickBar",
        component: () => import("@/views/zhuChart/BrickBar"),
        meta: {
            type: '基础分析'
        }
    },
    {
        // 矩阵箱线图
        path: "MatrixBoxPlot",
        name: "MatrixBoxPlot",
        component: () => import("@/views/Boxplot/MatrixBoxPlot"),
        meta: {
            type: '基础分析'
        }
    },
    {
        // 箱线图
        path: "Boxplot",
        name: "Boxplot",
        component: () => import("@/views/Boxplot/Boxplot"),
        meta: {
            type: '基础分析'
        }
    },
    {
        // 分组箱线图
        path: "MultigroupBoxplot",
        name: "MultigroupBoxplot",
        component: () => import("@/views/Boxplot/MultigroupBoxplot"),
        meta: {
            type: '基础分析'
        }
    },
    {
        // 贝塞尔曲线箱线图
        path: "BezierCurveBoxplot",
        name: "BezierCurveBoxplot",
        component: () => import("@/views/Boxplot/BezierCurveBoxplot"),
        meta: {
            type: '基础分析'
        }
    },
    {
        // 分组折线箱线图
        path: "GroupLineBoxBar",
        name: "GroupLineBoxBar",
        component: () => import("@/views/Boxplot/GroupLineBoxBar"),
        meta: {
            type: '基础分析'
        }
    },
    {
        // 百分比堆积面积图
        path: "PlotOfBulkPercentageArea",
        name: "PlotOfBulkPercentageArea",
        component: () => import("@/views/RiverChart/PlotOfBulkPercentageArea"),
        meta: {
            type: '基础分析'
        }
    },
    {
        // 堆积截断柱状图
        path: "AccumulationTruncationBar",
        name: "AccumulationTruncationBar",
        component: () => import("@/views/RiverChart/AccumulationTruncationBar"),
        meta: {
            type: '基础分析'
        }
    },
    {
        // 堆叠柱状图
        path: "StackPillar",
        name: "StackPillar",
        component: () => import("@/views/RiverChart/StackPillar"),
        meta: {
            type: '基础分析'
        }
    },
    {
        // 折叠河流图
        path: "StackWiringDiagram",
        name: "StackWiringDiagram",
        component: () => import("@/views/RiverChart/StackWiringDiagram"),
        meta: {
            type: '基础分析'
        }
    },
    {
        // 堆叠误差柱状图
        path: "StackErrorBar",
        name: "StackErrorBar",
        component: () => import("@/views/RiverChart/StackErrorBar"),
        meta: {
            type: '基础分析'
        }
    },
    //线图
    {
        path: "LineColumnDetail",
        name: "LineColumnDetail",
        component: () => import("@/views/lineChart/LineColumnDetail"),
        meta: {
            type: '基础分析'
        }
    },
    //动态折线图
    {
        path: "dynamicLine",
        name: "dynamicLine",
        component: () => import("@/views/lineChart/dynamicLine"),
        meta: {
            type: '基础分析'
        }
    },
    //分组折线图
    {
        path: "GroupingLineDetail",
        name: "GroupingLineDetail",
        component: () => import("@/views/lineChart/GroupingLineDetail"),
        meta: {
            type: '基础分析'
        }
    },
    //多组折线图
    {
        path: "LineMultipleGroups",
        name: "LineMultipleGroups",
        component: () => import("@/views/lineChart/LineMultipleGroups"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "LineTwoYAxisDetail",
        name: "LineTwoYAxisDetail",
        component: () => import("@/views/lineChart/LineTwoYAxisDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "LineDetail",
        name: "LineDetail",
        component: () => import("@/views/lineChart/LineDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "LineBevelDetail",
        name: "LineBevelDetail",
        component: () => import("@/views/lineChart/LineBevelDetail"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "LineError",
        name: "LineError",
        component: () => import("@/views/lineChart/LineErrorDetail"),
        meta: {
            type: '基础分析'
        }
    },

    {
        path: "AddSubtractMarkersDetail",
        name: "AddSubtractMarkersDetail",
        component: () => import("@/views/lineChart/AddSubtractMarkersDetail"),
        meta: {
            type: '基础分析'
        }
    },
    //平行坐标图
    {
        path: "LineParallelDetail",
        name: "LineParallelDetail",
        component: () => import("@/views/lineChart/LineParallelDetail"),
        meta: {
            type: '基础分析'
        }
    },
    //凹凸图
    {
        path: "BumpMapDetail",
        name: "BumpMapDetail",
        component: () => import("@/views/lineChart/BumpMapDetail"),
        meta: {
            type: '基础分析'
        }
    },
    // 坐标系河流图
    {
        path: "EasyRiver",
        name: "EasyRiver",
        component: () => import("@/views/RiverChart/EasyRiver"),
        meta: {
            type: '基础分析'
        }
    },
    {
        path: "flowLine",
        name: "flowLine",
        component: () => import("@/views/RiverChart/flowLine.vue"),
        meta: {
            type: '基础分析'
        }
    },
    //堆叠面积图
    {
        path: "AreaStackDetail",
        name: "AreaStackDetail",
        component: () => import("@/views/AreaFillChart/AreaStackDetail"),
        meta: {
            title: "堆叠面积图",
            type: '基础分析'
        },
    },
    //极坐标热力图
    {
        path: "AreaHeatmapRoundDetail",
        name: "AreaHeatmapRoundDetail",
        component: () => import("@/views/AreaFillChart/AreaHeatmapRoundDetail"),
        meta: {
            title: "极坐标热力图",
            type: '基础分析'
        },
    },
    //花式饼图
    {
        path: "PieFancyDetail",
        name: "PieFancyDetail",
        component: () => import("@/views/pie/PieFancyDetail"),
        meta: {
            title: "花式饼图",
            type: '基础分析'
        },
    },
    //复合折线柱状图
    {
        path: "LineHistogram",
        name: "LineHistogram",
        component: () => import("@/views/barCharts/LineHistogramDetail"),
        meta: {
            title: "复合折线柱状图",
            type: '基础分析'
        },
    },
    //正负分组柱状图
    {
        path: "BarPositiveNegativeDetail",
        name: "BarPositiveNegativeDetail",
        component: () => import("@/views/barCharts/BarPositiveNegativeDetail"),
        meta: {
            title: "正负分组柱状图",
            type: '基础分析'
        },
    },
    //堆积截断柱状图
    {
        path: "BarPiledUpCutoffDetail",
        name: "BarPiledUpCutoffDetail",
        component: () => import("@/views/barCharts/BarPiledUpCutoffDetail"),
        meta: {
            title: "堆积截断柱状图",
            type: '基础分析'
        },
    },
    //批量矩阵柱状图
    {
        path: "BarBatchMatrixDetail",
        name: "BarBatchMatrixDetail",
        component: () => import("@/views/barCharts/BarBatchMatrixDetail"),
        meta: {
            title: "批量矩阵柱状图",
            type: '基础分析'
        },
    },
    //多组分类柱状图
    {
        path: "BarMultipleGroupDetail",
        name: "BarMultipleGroupDetail",
        component: () => import("@/views/barCharts/BarMultipleGroupDetail"),
        meta: {
            title: "多组分类柱状图",
            type: '基础分析'
        },
    },
    //李克特图
    {
        path: "BarLikertDetail",
        name: "BarLikertDetail",
        component: () => import("@/views/barCharts/BarLikertDetail"),
        meta: {
            title: "李克特图",
            type: '基础分析'
        },
    },
    //双向条形图
    {
        path: "BarBiaxialStripDetail",
        name: "BarBiaxialStripDetail",
        component: () => import("@/views/barCharts/BarBiaxialStripDetail"),
        meta: {
            title: "双向条形图",
            type: '基础分析'
        },
    },
    //矩阵柱状图
    {
        path: "BarMatrixHistogram",
        name: "BarMatrixHistogram",
        component: () => import("@/views/barCharts/BarMatrixHistogram"),
        meta: {
            title: "矩阵柱状图",
            type: '基础分析'
        },
    },
    //测试图
    {
        path: "AAAAAA",
        name: "AAAAAA",
        component: () => import("@/views/3DAreaFillChart/AAAAAA"),
        meta: {
            type: '基础分析'
        },
    },
    //3D面积图
    {
        path: "Area3DChartDetail",
        name: "Area3DChartDetail",
        component: () => import("@/views/3DAreaFillChart/Area3DChartDetail"),
        meta: {
            title: "3D面积图",
            type: '基础分析'
        },
    },
    //面积图
    {
        path: "AreaLienchartDetail",
        name: "AreaLienchartDetail",
        component: () => import("@/views/AreaFillChart/AreaLienchartDetail"),
        meta: {
            title: "面积图",
            type: '基础分析'
        },
    },
    //热力图
    {
        path: "AreaHeatmapDetail",
        name: "AreaHeatmapDetail",
        component: () => import("@/views/AreaFillChart/AreaHeatmapDetail"),
        meta: {
            title: "热力图",
            type: '基础分析'
        },
    },
    //方块热图
    {
        path: "BlockHeatmapDetail",
        name: "BlockHeatmapDetail",
        component: () => import("@/views/AreaFillChart/BlockHeatmapDetail"),
        meta: {
            title: "方块热图",
            type: '基础分析'
        },
    },
    //分组热图
    {
        path: "GroupingsHeatmaps",
        name: "GroupingsHeatmaps",
        component: () => import("@/views/AreaFillChart/GroupingsHeatmaps"),
        meta: {
            title: "分组热图",
            type: '基础分析'
        },
    },
    //线型热图
    {
        path: "LineHeatmapDetail",
        name: "LineHeatmapDetail",
        component: () => import("@/views/AreaFillChart/LineHeatmapDetail"),
        meta: {
            title: "线型热图",
            type: '基础分析'
        },
    },
    //环形柱状图
    {
        path: "BarAnnularDetail",
        name: "BarAnnularDetail",
        component: () => import("@/views/barCharts/BarAnnularDetail"),
        meta: {
            title: "环形柱状图",
            type: '基础分析'
        },
    },
    //矩阵折线图
    {
        path: "LineMatrixDetail",
        name: "LineMatrixDetail",
        component: () => import("@/views/lineChart/LineMatrixDetail"),
        meta: {
            title: "矩阵折线图",
            type: '基础分析'
        },
    },
    //四象限图
    {
        path: "LineFourQuadrant",
        name: "LineFourQuadrant",
        component: () => import("@/views/lineChart/LineFourQuadrant"),
        meta: {
            title: "四象限图",
            type: '基础分析'
        },
    },
    //矩阵折线图(单条折线)
    {
        path: "LineSingleMatrixDetail",
        name: "LineSingleMatrixDetail",
        component: () => import("@/views/lineChart/LineSingleMatrixDetail"),
        meta: {
            title: "矩阵折线图(单条折线)",
            type: '基础分析'
        },
    },
    //分组百分比堆积图
    {
        path: "BarStackedChartDetail",
        name: "BarStackedChartDetail",
        component: () => import("@/views/barCharts/BarStackedChartDetail"),
        meta: {
            title: "分组百分比堆积图",
            type: '基础分析'
        },
    },
    //动态柱状图
    {
        path: "dynamicBar",
        name: "dynamicBar",
        component: () => import("@/views/barCharts/dynamicBar"),
        meta: {
            title: "动态柱状图",
            type: '基础分析'
        },
    },
    //百分比堆积图
    {
        path: "BarStackedThreeDetail",
        name: "BarStackedThreeDetail",
        component: () => import("@/views/barCharts/BarStackedThreeDetail"),
        meta: {
            title: "百分比堆积图",
            type: '基础分析'
        },
    },
    //堆叠蝴蝶图
    {
        path: "BarStackButterfliesDetail",
        name: "BarStackButterfliesDetail",
        component: () => import("@/views/barCharts/BarStackButterfliesDetail"),
        meta: {
            title: "堆叠蝴蝶图",
            type: '基础分析'
        },
    },
    //甘特图
    {
        path: "BarGanttDetail",
        name: "BarGanttDetail",
        component: () => import("@/views/barCharts/BarGanttDetail"),
        meta: {
            title: "甘特图",
            type: '基础分析'
        },
    },
    //双向分组柱状图
    {
        path: "BarBidirectionalDetail",
        name: "BarBidirectionalDetail",
        component: () => import("@/views/barCharts/BarBidirectionalDetail"),
        meta: {
            title: "双向分组柱状图",
            type: '基础分析'
        },
    },
    //比例柱状图
    {
        path: "BarProportionDetail",
        name: "BarProportionDetail",
        component: () => import("@/views/barCharts/BarProportionDetail"),
        meta: {
            title: "比例柱状图",
            type: '基础分析'
        },
    },
    //分组比例柱状图
    {
        path: "BarGroupRatioDetail",
        name: "BarGroupRatioDetail",
        component: () => import("@/views/barCharts/BarGroupRatioDetail"),
        meta: {
            title: "分组比例柱状图",
            type: '基础分析'
        },
    },
    //正负柱状图（内嵌）
    {
        path: "BarEmbeddedDetail",
        name: "BarEmbeddedDetail",
        component: () => import("@/views/barCharts/BarEmbeddedDetail"),
        meta: {
            title: "正负柱状图（内嵌）",
            type: '基础分析'
        },
    },
    //填充面积图
    {
        path: "AreaFillDetail",
        name: "AreaFillDetail",
        component: () => import("@/views/AreaFillChart/AreaFillDetail"),
        meta: {
            title: "填充面积图",
            type: '基础分析'
        },
    },
    //深度面积图
    {
        path: "AreaDepthDetail",
        name: "AreaDepthDetail",
        component: () => import("@/views/AreaFillChart/AreaDepthDetail"),
        meta: {
            title: "深度面积图",
            type: '基础分析'
        },
    },
    //分半对比饼图
    {
        path: "AreaTwoRatioDetail",
        name: "AreaTwoRatioDetail",
        component: () => import("@/views/pie/AreaTwoRatioDetail"),
        meta: {
            title: "分半对比饼图",
            type: '基础分析'
        },
    },
    //分半对比饼图
    {
        path: "Pie3DBarDetail",
        name: "Pie3DBarDetail",
        component: () => import("@/views/pie/Pie3DBarDetail"),
        meta: {
            title: "3D饼图",
            type: '基础分析'
        },
    },
    //3D饼图
    {
        path: "Pie3DThreeDetail",
        name: "Pie3DThreeDetail",
        component: () => import("@/views/pie/Pie3DThreeDetail"),
        meta: {
            title: "3D饼图",
            type: '基础分析'
        },
    },
    //坐标饼图
    {
        path: "PieAxisDetail",
        name: "PieAxisDetail",
        component: () => import("@/views/pie/PieAxisDetail"),
        meta: {
            title: "坐标饼图",
            type: '基础分析'
        },
    },
    //旭日饼图
    {
        path: "RisingSunPie",
        name: "RisingSunPie",
        component: () => import("@/views/pie/RisingSunPie"),
        meta: {
            title: "旭日饼图",
            type: '基础分析'
        },
    },
    //月亮饼图
    {
        path: "MoonPie",
        name: "MoonPie",
        component: () => import("@/views/pie/MoonPie"),
        meta: {
            title: "月亮饼图",
            type: '基础分析'
        },
    },
    //词云图
    {
        path: "WordCloudDetail",
        name: "WordCloudDetail",
        component: () => import("@/views/WordCloud/WordCloudDetail"),
        meta: {
            title: "词云图",
            type: '基础分析'
        },
    },
    //哑铃图
    {
        path: "LineDumbbell",
        name: "LineDumbbell",
        component: () => import("@/views/dumbbellDiagramCharts/LineDumbbellDetail"),
        meta: {
            title: "哑铃图",
            type: '基础分析'
        },
    },
    //分组棒棒糖图
    {
        path: "GroupLollipopDetail",
        name: "GroupLollipopDetail",
        component: () => import("@/views/pointCharts/GroupLollipopDetail"),
        meta: {
            title: "分组棒棒糖图",
            type: '基础分析'
        },
    },
    //棒棒糖图
    {
        path: "LollipopPieDetail",
        name: "LollipopPieDetail",
        component: () => import("@/views/pointCharts/LollipopPieDetail"),
        meta: {
            title: "棒棒糖图",
            type: '基础分析'
        },
    },
    //散点图+地图
    {
        path: "MapPlusScatterDetail",
        name: "MapPlusScatterDetail",
        component: () => import("@/views/map/MapPlusScatterDetail"),
        meta: {
            title: "散点图+地图",
            type: '基础分析'
        },
    },
    //世界地图散点图
    {
        path: "WorldScatterMap",
        name: "WorldScatterMap",
        component: () => import("@/views/map/WorldScatterMap"),
        meta: {
            title: "世界地图散点图",
            type: '基础分析'
        },
    },
    //世界地图散点图
    {
        path: "WorldScatterGroupMap",
        name: "WorldScatterGroupMap",
        component: () => import("@/views/map/WorldScatterGroupMap"),
        meta: {
            title: "世界地图散点图",
            type: '基础分析'
        },
    },
    //世界柱状图地图
    {
        path: "WordBarDetail",
        name: "WordBarDetail",
        component: () => import("@/views/map/WordBarDetail"),
        meta: {
            title: "世界柱状图地图",
            type: '基础分析'
        },
    },
    //世界饼图地图
    {
        path: "WordPieDetail",
        name: "WordPieDetail",
        component: () => import("@/views/map/WordPieDetail"),
        meta: {
            title: "世界饼图地图",
            type: '基础分析'
        },
    },
    //世界地图（映射）
    {
        path: "WorldMapMapping",
        name: "WorldMapMapping",
        component: () => import("@/views/map/WorldMapMapping"),
        meta: {
            title: "世界地图（映射）",
            type: '基础分析'
        },
    },
    //分组堆积柱状图
    {
        path: "BarGroupStackDetail",
        name: "BarGroupStackDetail",
        component: () => import("@/views/barCharts/BarGroupStackDetail"),
        meta: {
            title: "分组堆积柱状图",
            type: '基础分析'
        },
    },
    //分组百分比柱状图
    {
        path: "BarPercentageDetail",
        name: "BarPercentageDetail",
        component: () => import("@/views/barCharts/BarPercentageDetail"),
        meta: {
            title: "分组百分比柱状图",
            type: '基础分析'
        },
    },
    //玉块图
    {
        path: "JadeBlocksDetail",
        name: "JadeBlocksDetail",
        component: () => import("@/views/barCharts/JadeBlocksDetail"),
        meta: {
            title: "玉块图",
            type: '基础分析'
        },
    },
    //表格
    {
        path: "CreateTable",
        name: "CreateTable",
        component: () => import("@/views/tableFormat/CreateTable"),
        meta: {
            title: "表格",
            type: '基础分析'
        },
    },
    //堆叠柱状图
    {
        path: "StackUpBarDetail",
        name: "StackUpBarDetail",
        component: () => import("@/views/barCharts/StackUpBarDetail"),
        meta: {
            title: "堆叠柱状图",
            type: '基础分析'
        },
    },
    //分组堆积柱状图
    {
        path: "GroupingStackedHistogramsDetail",
        name: "GroupingStackedHistogramsDetail",
        component: () => import("@/views/barCharts/GroupingStackedHistogramsDetail"),
        meta: {
            title: "分组堆积柱状图",
            type: '基础分析'
        },
    },
    //矩阵百分比柱状图（带连线）
    {
        path: "matrixBarLineDetail",
        name: "matrixBarLineDetail",
        component: () => import("@/views/barCharts/matrixBarLineDetail"),
        meta: {
            title: "矩阵百分比柱状图（连线）",
            type: '基础分析'
        },
    },
    //3D柱状图
    {
        path: "Bar3DStackDetail",
        name: "Bar3DStackDetail",
        component: () => import("@/views/barCharts/Bar3DStackDetail"),
        meta: {
            title: "3D柱状图",
            type: '基础分析'
        },
    },
    //堆积柱状图
    {
        path: "BarPiledDetail",
        name: "BarPiledDetail",
        component: () => import("@/views/barCharts/BarPiledDetail"),
        meta: {
            title: "堆积柱状图",
            type: '基础分析'
        },
    },
    //柱泡图
    {
        path: "BarDubbleDetail",
        name: "BarDubbleDetail",
        component: () => import("@/views/barCharts/BarDubbleDetail"),
        meta: {
            title: "柱泡图",
            type: '基础分析'
        },
    },
    // 成比例面积图
    {
        path: "AreaScaleChartDetail",
        name: "AreaScaleChartDetail",
        component: () => import("@/views/AreaFillChart/AreaScaleChartDetail"),
        meta: {
            type: '基础分析'
        },
    },
    {
        path: 'dimensionLine',
        name: 'dimensionLine',
        component: () => import('@/views/lineChart/dimensionLine.vue'),
        meta: {
            title: '3维折线图',
            type: '基础分析'
        }
    },
    {
        path: "radialBoxplot",
        name: "radialBoxplot",
        component: () => import("@/views/Boxplot/radialBoxplot"),
        meta: {
            title: "极坐标箱线图",
            type: '基础分析'
        },
    },
    {
        path: "stackedColumn",
        name: "stackedColumn",
        component: () => import("@/views/stackBar/stackedColumn"),
        meta: {
            title: "堆叠柱形图（极坐标）",
            type: '基础分析'
        },
    },
    {
        path: "spanDiagram",
        name: "spanDiagram",
        component: () => import("@/views/barCharts/spanDiagram"),
        meta: {
            title: "跨度图（极坐标）",
            type: '基础分析'
        },
    },
    {
        path: "3DLine",
        name: "3DLine",
        component: () => import("@/views/lineChart/3DLine"),
        meta: {
            title: "分组3D折线图",
            type: '基础分析'
        },
    },
    {
        path: 'lineArea',
        name: 'lineArea',
        component: () => import('@/views/PieChart/lineArea'),
    },
    {
        path: 'stackAreaBar',
        name: 'stackAreaBar',
        component: () => import('@/views/lineChart/stackAreaBar'),
    },
    {
        path: 'groupTwoBar',
        name: 'groupTwoBar',
        component: () => import('@/views/zhuzhuangtu/groupTwoBar'),
    },
    {
        path: 'marginalStatisticsBubble',
        name: 'marginalStatisticsBubble',
        component: () => import('@/views/bubbleCharts/marginalStatisticsBubble'),
    },
    {
        path: 'polarBubbleArea',
        name: 'polarBubbleArea',
        component: () => import('@/views/PieChart/polarBubbleArea'),
    },
    {
        path: 'bubble3D',
        name: 'bubble3D',
        component: () => import('@/views/pointCharts/bubble3D'),
    },
    {
        path: 'smoothPoint',
        name: 'smoothPoint',
        component: () => import('@/views/pointCharts/smoothPoint'),
    },
    {
        path: 'groupMarixPie',
        name: 'groupMarixPie',
        component: () => import('@/views/PieChart/groupMarixPie'),
    },
    {
        path: 'groupLollipop',
        name: 'groupLollipop',
        component: () => import('@/views/pointCharts/groupLollipop'),
    },
    {
        path: 'errorNestedBar',
        name: 'errorNestedBar',
        component: () => import('@/views/barCharts/errorNestedBar'),
    },
    {
        path: 'arcNetwork',
        name: 'arcNetwork',
        component: () => import('@/views/TreeCharts/arcNetwork'),
    },
    {
        path: '3DSurfaceWave2',
        name: '3DSurfaceWave2',
        component: () => import('@/views/lineChart/3DSurfaceWave2'),
    },
    {
        path: 'categoricalBubble',
        name: 'categoricalBubble',
        component: () => import('@/views/bubbleCharts/categoricalBubble'),
    },
    {
        path: 'starLollipop',
        name: 'starLollipop',
        component: () => import('@/views/pointCharts/starLollipop'),
    },
    {
        path: 'waffleChart',
        name: 'waffleChart',
        component: () => import('@/views/PieChart/waffleChart'),
    },
    {
        path: 'gradientBubble',
        name: 'gradientBubble',
        component: () => import('@/views/bubbleCharts/gradientBubble'),
    },
    {
        path: 'VoronoiTree',
        name: 'VoronoiTree',
        component: () => import('@/views/PieChart/VoronoiTree'),
    },
    {
        path: 'VoronoiTreemaps',
        name: 'VoronoiTreemaps',
        component: () => import('@/views/PieChart/VoronoiTreemaps'),
    },
    {
        path: 'pieNetwork',
        name: 'pieNetwork',
        component: () => import('@/views/TreeCharts/pieNetwork'),
    },
    {
        path: 'TruncationBar2',
        name: 'TruncationBar2',
        component: () => import('@/views/barCharts/TruncationBar2'),
    },
    {
        path: 'ladderLine',
        name: 'ladderLine',
        component: () => import('@/views/lineChart/ladderLine'),
    },
    {
        path: 'basicFunnel',
        name: 'basicFunnel',
        component: () => import('@/views/barCharts/basicFunnel'),
    },
    {
        path: 'ternaryContours',
        name: 'ternaryContours',
        component: () => import('@/views/pointCharts/ternaryContours'),
    },
    {
        path: 'gradientTernaryContours',
        name: 'gradientTernaryContours',
        component: () => import('@/views/pointCharts/gradientTernaryContours'),
    },
    {
        path: 'groupPointBoxplot',
        name: 'groupPointBoxplot',
        component: () => import('@/views/Boxplot/groupPointBoxplot'),
    },
    {
        path: '3dCurveGraph',
        name: '3dCurveGraph',
        component: () => import('@/views/lineChart/3dCurveGraph'),
    },
    {
        path: 'ringGroupStackBar',
        name: 'ringGroupStackBar',
        component: () => import('@/views/zhuzhuangtu/ringGroupStackBar'),
    },
    {
        path: 'ladderLineBar',
        name: 'ladderLineBar',
        component: () => import('@/views/zhuzhuangtu/ladderLineBar'),
    },
    {
        path: 'radarPie',
        name: 'radarPie',
        component: () => import('@/views/polarCharts/radarPie'),
    },
    {
        path: 'ringRadar',
        name: 'ringRadar',
        component: () => import('@/views/polarCharts/ringRadar'),
    },
]

const basicAnalysisWithTypes = basicAnalysis.map(item => {
    if (!item.meta) {
        // 没有meta对象
        return {
            ...item,
            meta: {
                type: "基础分析",
            },
        };
    }

    // 有meta对象
    return {
        ...item,
        meta: {
            ...item.meta,
            type: "基础分析", // 替换成你想要的类型值
        },
    };
});

export default basicAnalysisWithTypes