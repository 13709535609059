import { render, staticRenderFns } from "./CopyPlus.vue?vue&type=template&id=253717cf&scoped=true"
import script from "./CopyPlus.vue?vue&type=script&lang=js"
export * from "./CopyPlus.vue?vue&type=script&lang=js"
import style0 from "./CopyPlus.vue?vue&type=style&index=0&id=253717cf&prod&lang=scss&scoped=true"
import style1 from "./CopyPlus.vue?vue&type=style&index=1&id=253717cf&prod&lang=css"
import style2 from "./CopyPlus.vue?vue&type=style&index=2&id=253717cf&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "253717cf",
  null
  
)

export default component.exports