import request from "@/utils/request";
import { Loading, Message } from 'element-ui';
import bus from "@/utils/bus";
let loadinginstace;
const api = {
    //获取item图形列表
    getItem(params) {
        loadinginstace = Loading.service({
            lock: true,
            text: '正在处理，请稍后。。。',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)',
        });
        return request.post('/categorys', params || {}).then(res => {
            // bus.$emit("Send_last_time", res.data);
            localStorage.setItem("Send_last_time", JSON.stringify
            (res.data));
            console.log(res);
            loadinginstace && loadinginstace.close();
            return res.data
        }).catch(err => {
            loadinginstace && loadinginstace.close();
        });
    },
    //获取双环饼图数据
    getDualRingPieChart(params) {
        return request.post('/PieChartTool/PieChart', params || {}).then(res => res.data);
    },
    //获取双饼图数据
    getTwoPieChart(params) {
        return request.post('/upload/1203006', params || {}).then(res => res.data);
    },
    getData(params) {
        return request.post('/ScRNAseqPipeline/ScRNAseqStep1', params || {}).then(res => res.data);
    },
    // 获取项目记录列表,删除某条项目记录的接口
    getHistoryData(params) {
        loadinginstace = Loading.service({
            lock: true,
            text: '正在处理，请稍后。。。',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)',
        });
        return request.post('/phistory', params || {}).then(res => {
            loadinginstace && loadinginstace.close();
            return res.data
        }).catch(err => {
            loadinginstace && loadinginstace.close();
        });
    },
    // 获取项目列表后然后再通过这个接口获取图片
    // getUrlData(params){
    //     return request.post('/projects', params || {}).then(res => res.data);
    // },

    // 获取做图详情，跳转做图界面,还可以作为搜索框
    getDetailData(params) {
        return request.post('/projects', params || {}).then(res => res.data);
    },
    // 用户获取会员的接口
    getVipData(params) {
        return request.post('/vipStrategy', params || {}).then(res => res.data);
    },
    // 这是请求支付二维码
    getVipCode(params) {
        return request.post('/OnlinePay/start', params || {}).then(res => res.data);
    },
    // 请求PDF地址
    GetPDFdata(params) {
        return request.post('/html2pdf', params || {}).then(res => res.data);
    },
    // 获取评论
    getTalkData(params) {
        return request.post('/message', params || {}).then(res => res.data);
    },
    // 这个是上传图片获取vip的接口
    getRecordVIP(params) {
        return request.post('/bhistory', params || {}).then(res => res.data);
    },
    // 公众号
    getOfficialAccount(params) {
        return request.post('/article', params || {}).then(res => res.data);
    },
    // AI提问次数
    getAINum(params) {
        return request.post('/chat/counts', params || {}).then(res => res.data);
    },
    //  获取四种购买次数的策略
    getAIList(params) {
        return request.post('/chat/strategy', params || {}).then(res => res.data);
    },
    // 购买次数
    buyGPTNums(params) {
        return request.post('/chat/buy', params || {}).then(res => res.data);
    },
    // 获取gpts购买记录
    getRecord(params) {
        return request.post('/chat/bhistory', params || {}).then(res => res.data);
    }, 
    // 获得新图上线提醒
    // 获得站内消息相关接口
    getNews(params) {
        return request.post('/tips', params || {}).then(res => res.data);
    },
    // 图表收藏类相关接口
    collect(params){
        return request.post('/favorites', params || {}).then(res => res.data);
    },
    downloadPackage(params) {
        loadinginstace = Loading.service({
            lock: true,
            text: '正在处理，请稍后。。。',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)',
        });
        return request.get(`${params}`, {}).then(res => {
            loadinginstace && loadinginstace.close();
            return res.data
        }).catch(err => {
            loadinginstace && loadinginstace.close();
        });
        // return request.get(`${params}`,  {}).then(res => res.data);
    },
    downloadCode(params){
        return request.post('/favorites', params || {}).then(res => res.data);
    }
}

export default api;